/* Start - Override css properties for main.css */
body {
    font-size: 13px;
}

.okc-table table thead tr th,
.okc-table table thead tr td {
    padding: 10px 3px;
}

.okc-table table thead .sort:before {
    right: 2px;
}

.okc-table table thead th.sort {
    padding-right: 12px !important;
}

.okc-table table tbody tr td {
    padding: 3px;
}
/* End - Override css properties for main.css */

.upload-registration__table thead tr:first-child th {
    border-left-color: transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid #afafaf;
    font-size: 20px;
}

.upload-registration__table thead tr:first-child th:after {
    display: none;
}

.upload-registration__table thead tr:last-child th {
    border-top: 2px solid #afafaf;
}

.upload-registration__table thead tr:last-child th:first-child {
    border-left: 3px solid #afafaf;
}

.upload-registration__table thead tr:last-child th:last-child {
    border-right: 2px solid #afafaf;
}

.upload-registration__table thead th {
    background-color: #f0f0f0;
}

.upload-registration__table tbody tr td:first-child {
    border-left: 3px solid #afafaf;
}

.upload-registration__table tbody tr td:last-child {
    border-right: 2px solid #afafaf;
}

.upload-registration__table tbody tr:last-child td {
    border-bottom: 2px solid #afafaf;
}

.upload-registration__table .form-check.custom-checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.upload-registration__table .form-check.custom-checkbox .form-check-label {
    height: 20px;
    width: 100%;
    padding: 0;
}

.upload-registration__table .form-check.custom-checkbox .form-check-label:after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.upload-registration__table .form-check.custom-checkbox .form-check-label:before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.upload-registration__table .form-check.custom-checkbox .form-check-input:checked ~ .form-check-label:after {
    left: calc(50% + 8px);
}

.upload-registration__table thead tr:last-child th:nth-child(3) {
    min-width: 80px;
}

.upload-registration__table thead tr:last-child th:nth-child(6) {
    min-width: 45px;
}

.upload-registration__table thead tr:last-child th:nth-child(7),
.upload-registration__table thead tr:last-child th:nth-child(8) {
    min-width: 105px;
}

.upload-registration__table thead tr:last-child th:nth-child(11) {
    max-width: 120px;
}

.upload-registration__table thead tr:last-child th:nth-child(12) {
    min-width: 80px;
}

.okc-upload .dropzone {
    border: 2px dashed #d66f00;
    min-height: 100px;
    width: 100%;
    padding: 50px;
}

.okc-upload .dropzone--single {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.okc-upload--error .dropzone {
    border-color: #eb2013;
}

.okc-upload--error .okc-error.d-none {
    display: block !important;
}

.dropzone,
.dropzone * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.dropzone {
    min-height: 150px;
    background: white;
    padding: 20px 20px;
}

.dropzone.dz-clickable {
    cursor: pointer;
}

.dropzone.dz-clickable * {
    cursor: default;
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
    cursor: pointer;
}

.dropzone.dz-started .dz-message {
    display: none;
}

.dropzone.dz-drag-hover {
    border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
    opacity: 0.5;
}

.dropzone .dz-message {
    text-align: center;
    margin: 2em 0;
}

.dropzone .dz-message .dz-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.dropzone .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px;
}

.dropzone .dz-preview:hover {
    z-index: 1000;
}

.dropzone .dz-preview:hover .dz-details {
    opacity: 1;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
    border-radius: 20px;
    background: #999;
    background: -webkit-gradient(linear, left top, left bottom, from(#eee), to(#ddd));
    background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone .dz-preview.dz-file-preview .dz-details {
    opacity: 1;
}

.dropzone .dz-preview.dz-image-preview {
    background: white;
}

.dropzone .dz-preview.dz-image-preview .dz-details {
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.dropzone .dz-preview .dz-remove {
    font-size: 14px;
    text-align: center;
    display: block;
    cursor: pointer;
    border: none;
}

.dropzone .dz-preview .dz-remove:hover {
    text-decoration: underline;
}

.dropzone .dz-preview:hover .dz-details {
    opacity: 1;
}

.dropzone .dz-preview .dz-details {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 13px;
    min-width: 100%;
    max-width: 100%;
    padding: 2em 1em;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    line-height: 150%;
}

.dropzone .dz-preview .dz-details .dz-size {
    margin-bottom: 1em;
    font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
    white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
    border: 1px solid rgba(200, 200, 200, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
    border: 1px solid transparent;
}

.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0 0.4em;
    border-radius: 3px;
}

.dropzone .dz-preview:hover .dz-image img {
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    -webkit-filter: blur(8px);
    filter: blur(8px);
}

.dropzone .dz-preview .dz-image {
    border-radius: 20px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    position: relative;
    display: block;
    z-index: 10;
}

.dropzone .dz-preview .dz-image img {
    display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
    -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-error .dz-error-mark {
    opacity: 1;
    -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
    pointer-events: none;
    opacity: 0;
    z-index: 500;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -27px;
}

.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
    display: block;
    width: 54px;
    height: 54px;
}

.dropzone .dz-preview.dz-processing .dz-progress {
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-in;
    transition: opacity 0.4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
    -webkit-animation: pulse 6s ease infinite;
    animation: pulse 6s ease infinite;
}

.dropzone .dz-preview .dz-progress {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    height: 16px;
    left: 50%;
    top: 50%;
    margin-top: -8px;
    width: 80px;
    margin-left: -40px;
    background: rgba(255, 255, 255, 0.9);
    -webkit-transform: scale(1);
    border-radius: 8px;
    overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
    background: #333;
    background: -webkit-gradient(linear, left top, left bottom, from(#666), to(#444));
    background: linear-gradient(to bottom, #666, #444);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    -webkit-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
}

.dropzone .dz-preview.dz-error .dz-error-message {
    display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
    opacity: 1;
    pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
    pointer-events: none;
    z-index: 1000;
    position: absolute;
    display: none;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    border-radius: 8px;
    font-size: 13px;
    top: 130px;
    left: -10px;
    width: 140px;
    background: #be2626;
    background: -webkit-gradient(linear, left top, left bottom, from(#be2626), to(#a92222));
    background: linear-gradient(to bottom, #be2626, #a92222);
    padding: 0.5em 1.2em;
    color: white;
}

.dropzone .dz-preview .dz-error-message:after {
    content: '';
    position: absolute;
    top: -6px;
    left: 64px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #be2626;
}

.upload-confirm__error {
    padding: 20px;
    border: 1px solid #e1e6ea;
    background: #fff;
    border-radius: 4px;
    font-size: 13px;
}

.upload-confirm__error p {
    color: #eb2013;
    margin-bottom: 0;
}

.upload-confirm__error p:not(:last-child) {
    margin-bottom: 15px;
}

#errorMessageUploadModal .modal-body {
    max-height: 70vh;
    overflow-y: auto;
}

.upload-registration .btn-block {
    width: auto;
}

.upload-registration .discount-rate {
    width: 80px;
    display: flex;
    align-items: center;
    gap: 5px;
}

#warning-modal #deleted-csv-table thead tr:nth-child(2) th:nth-child(2),
#warning-modal #deleted-csv-table thead tr:nth-child(2) th:nth-child(3) {
    min-width: 110px;
}

#warning-modal #deleted-csv-table thead tr:nth-child(2) th:first-child,
#warning-modal #deleted-csv-table thead tr:nth-child(2) th:nth-child(7),
#warning-modal #deleted-csv-table thead tr:nth-child(2) th:nth-child(8) {
    min-width: 85px;
}

#warning-modal .okc-table {
    margin-bottom: 0;
}

#warning-modal .deleted-csv-option {
    display: flex;
    justify-content: center;
    padding: 25px;
}

#competitor-prohibited-items-modal table thead th:nth-child(3) {
    min-width: 60px;
}

#competitor-prohibited-items-modal .okc-table {
    margin-bottom: 10px;
}

#application-table th {
    background: #f0f0f0;
    border-top: 2px solid #afafaf;
}

#application-table thead tr:last-child th:first-child,
#application-table tbody td:first-child {
    border-left: 2px solid #afafaf;
}

#application-table tbody tr td {
    padding: 14px;
}

#application-table thead tr:last-child th:last-child,
#application-table tbody td:last-child {
    border-right: 2px solid #afafaf;
}

#application-table tbody tr:last-child td {
    border-bottom: 2px solid #afafaf;
}

.okc-table-title {
    background-color: rgb(240, 240, 240);
    font-weight: 700;
    font-size: 20px;
    padding: 10px 3px;
}
