.export-summary .error-message {
    border: 1px solid red;
    padding: 12px;
    width: 100%;
}

.export-summary .okc-error {
    margin-top: -17px;
}

.export-summary .ok-error {
    font-size: 12px;
    color: #eb2013;
    text-align: left;
}

.export-summary .row {
    --bs-gutter-x: 0;
}

.export-summary .no-gutters {
    margin-top: -17px;
}

.btn.btn--secondary:hover {
    background: #ddd;
    color: #000;
}
