.tab-content {
    padding-bottom: 30px;
}

.tab_nav {
    position: absolute;
}

.tab-content.column {
    height: 36px !important;
    padding: 0px !important;
}

.tab-content.checkbox {
    padding: 0px !important;
}

.okc-table > thead > tr > th.checkbox {
    padding: 0px !important;
}

.btn.btn--secondary:hover {
    background: #d66f00;
    color: #fff;
}

.ok-navtabs__link.nav-link {
    border-radius: 0;
    border: 1px solid #d66f00;
    color: #d66f00;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #d66f00 !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #fff;
    background-color: #d66f00;
    border-color: #d66f00;
}

.ok-navtabs__link.nav-link:first-child {
    border-top-left-radius: 4px;
}

.ok-navtabs__link.nav-link:last-child {
    border-top-right-radius: 4px;
}
