.row.no-gutters {
    overflow: auto;
}

.select-menu {
    height: 100vh;
}

.select-menu__inner {
    padding: 100px 0 50px;
}

.select-menu__logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 30px;
}

.select-menu__logo--error {
    margin-bottom: 25px;
}

.select-menu__logo--error .icon-image--broken-link {
    width: 150px;
    height: 150px;
}

.select-menu__logo img {
    width: 100px;
}

.select-menu__form {
    border: 1px solid #f0f0f0;
    padding: 40px 20px;
    border-radius: 8px;
    background: #fff;
    display: flex;
    flex-direction: column;
}

.select-menu__form .form-title {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
}

.select-menu .form-option {
    border: none;
    background-color: #ffffff;
}

.select-menu__form .form-option:nth-child(2) {
    border-top: solid 2px #e77e00;
}

.select-menu__form .form-option {
    font-size: 20px;
    font-weight: 600;
    color: #e77e00;
    text-align: center;
    padding: 20px;
    border-bottom: solid 2px #e77e00;
}

.select-menu__form .form-option:nth-child(1) {
    margin-top: 20px;
}

.select-menu__form .form-option:hover {
    background-color: #e77e00;
    color: #ffffff;
    cursor: pointer;
}

.select-menu__form.c-slide-top {
    border-width: 2px;
}

.select-menu__logo img {
    width: 200px;
}

button:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
}
