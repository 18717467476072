@charset "UTF-8";
/*!
 * Bootstrap Grid v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container,
    .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container,
    .container-sm,
    .container-md {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1140px;
    }
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.row-cols-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.row-cols-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.row-cols-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.row-cols-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.row-cols-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

.row-cols-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
}

.order-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
}

.order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
}

.order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}

.order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
}

.order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
}

.order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
}

.order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
}

.order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
}

.order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
}

.order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
}

.order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
}

.order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
}

.order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
}

.order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
}

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.66667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333%;
}

.offset-5 {
    margin-left: 41.66667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333%;
}

.offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-sm-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-sm-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-sm-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .row-cols-sm-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-sm-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-sm-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-sm-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-sm-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-sm-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-sm-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-sm-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-sm-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-sm-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-sm-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-sm-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .order-sm-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }

    .order-sm-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }

    .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }

    .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .order-sm-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }

    .order-sm-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }

    .order-sm-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }

    .order-sm-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }

    .order-sm-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }

    .order-sm-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }

    .order-sm-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }

    .order-sm-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }

    .order-sm-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }

    .order-sm-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.33333%;
    }

    .offset-sm-2 {
        margin-left: 16.66667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.33333%;
    }

    .offset-sm-5 {
        margin-left: 41.66667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.33333%;
    }

    .offset-sm-8 {
        margin-left: 66.66667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.33333%;
    }

    .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-md-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-md-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-md-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .row-cols-md-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-md-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-md-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-md-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-md-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-md-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .order-md-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }

    .order-md-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }

    .order-md-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }

    .order-md-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .order-md-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }

    .order-md-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }

    .order-md-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }

    .order-md-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }

    .order-md-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }

    .order-md-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }

    .order-md-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }

    .order-md-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }

    .order-md-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }

    .order-md-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.33333%;
    }

    .offset-md-2 {
        margin-left: 16.66667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.33333%;
    }

    .offset-md-5 {
        margin-left: 41.66667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.33333%;
    }

    .offset-md-8 {
        margin-left: 66.66667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.33333%;
    }

    .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-lg-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-lg-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-lg-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .row-cols-lg-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-lg-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-lg-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .order-lg-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }

    .order-lg-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }

    .order-lg-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }

    .order-lg-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .order-lg-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }

    .order-lg-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }

    .order-lg-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }

    .order-lg-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }

    .order-lg-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }

    .order-lg-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }

    .order-lg-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }

    .order-lg-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }

    .order-lg-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }

    .order-lg-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.33333%;
    }

    .offset-lg-2 {
        margin-left: 16.66667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.33333%;
    }

    .offset-lg-5 {
        margin-left: 41.66667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.33333%;
    }

    .offset-lg-8 {
        margin-left: 66.66667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.33333%;
    }

    .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-xl-1 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-xl-2 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-xl-3 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .row-cols-xl-4 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-xl-5 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-xl-6 > * {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .order-xl-last {
        -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }

    .order-xl-0 {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }

    .order-xl-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }

    .order-xl-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .order-xl-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }

    .order-xl-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }

    .order-xl-5 {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }

    .order-xl-6 {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }

    .order-xl-7 {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }

    .order-xl-8 {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }

    .order-xl-9 {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }

    .order-xl-10 {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }

    .order-xl-11 {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }

    .order-xl-12 {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.33333%;
    }

    .offset-xl-2 {
        margin-left: 16.66667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.33333%;
    }

    .offset-xl-5 {
        margin-left: 41.66667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.33333%;
    }

    .offset-xl-8 {
        margin-left: 66.66667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.33333%;
    }

    .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-md-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-print-inline-flex {
        display: -webkit-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 576px) {
    .flex-sm-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-sm-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-sm-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-sm-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-sm-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-sm-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-md-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-md-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-md-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-md-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-md-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-md-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-md-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-md-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-lg-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-lg-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-lg-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-lg-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-lg-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-xl-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
        -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-xl-start {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-xl-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-xl-start {
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        -webkit-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-xl-baseline {
        -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }

    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -1rem !important;
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }

    .m-sm-n4 {
        margin: -1.5rem !important;
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }

    .m-sm-n5 {
        margin: -3rem !important;
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }

    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -1rem !important;
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }

    .m-md-n4 {
        margin: -1.5rem !important;
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }

    .m-md-n5 {
        margin: -3rem !important;
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }

    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -1rem !important;
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }

    .m-lg-n4 {
        margin: -1.5rem !important;
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }

    .m-lg-n5 {
        margin: -3rem !important;
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.5rem !important;
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xl-5 {
        margin: 3rem !important;
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }

    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 3rem !important;
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }

    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -1rem !important;
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }

    .m-xl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }

    .m-xl-n5 {
        margin: -3rem !important;
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

/*!
 * Bootstrap Reboot v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-family: 'Noto Sans JP', sans-serif;
    line-height: 1.15;
    font-style: normal;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: 'Hiragino Sans', 'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Kaku Gothic Pro', '游ゴシック', 'メイリオ', 'MS ゴシック',
        'sans-serif';
    line-height: 1.5;
    color: #222222;
    text-align: left;
    background: #f8fafc;
    font-size: 13px;
    font-weight: 400;
    overflow: hidden;
}

[tabindex='-1']:focus:not(:focus-visible) {
    outline: 0 !important;
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus:not(:focus-visible) {
    outline: 0;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

[role='button'] {
    cursor: pointer;
}

select {
    word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans',
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1,
.h1 {
    font-size: 2.5rem;
}

h2,
.h2 {
    font-size: 2rem;
}

h3,
.h3 {
    font-size: 1.75rem;
}

h4,
.h4 {
    font-size: 1.5rem;
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1rem;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}

.blockquote-footer::before {
    content: '\2014\00A0';
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #6c757d;
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
}

a > code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-sm > .table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-md > .table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-lg > .table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xl > .table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
    border: 0;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition:
        border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition:
        border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        -webkit-transition: none;
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

input[type='date'].form-control,
input[type='time'].form-control,
input[type='datetime-local'].form-control,
input[type='month'].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
    height: auto;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row > .col,
.form-row > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
    color: #6c757d;
}

.form-check-label {
    margin-bottom: 0;
    z-index: 0;
}

.form-check-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.9);
    border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*='col-'] > .valid-tooltip {
    left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
    display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #28a745;
    padding-right: calc(0.75em + 2.3125rem);
    background:
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            right 0.75rem center/8px 10px no-repeat,
        #fff
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
            center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
    color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
    color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
    border-color: #34ce57;
    background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
    border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*='col-'] > .invalid-tooltip {
    left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
    display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background:
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            right 0.75rem center/8px 10px no-repeat,
        #fff
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
            center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
    color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 0;
    }

    .form-inline .form-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-control-plaintext {
        display: inline-block;
    }

    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto;
    }

    .form-inline .form-check {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }

    .form-inline .form-check-input {
        position: relative;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }

    .form-inline .custom-control {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
    width: 100%;
}

.fade {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        -webkit-transition: none;
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        -webkit-transition: none;
        transition: none;
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.dropdown-menu-left {
    right: auto;
    left: 0;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

@media (min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
}

.dropleft .dropdown-toggle::after {
    display: none;
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
    right: auto;
    bottom: auto;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
    z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
    z-index: 1;
}

.btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
    margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
    width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
}

.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
    margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
    z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
    margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
    height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
    height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
    padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.custom-control-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e9ecef;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: '';
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #007bff;
    background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
    padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        -webkit-transform 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        -webkit-transform 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition:
        transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition:
        transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        -webkit-transform 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none;
    }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
    transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        right 0.75rem center/8px 10px no-repeat;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}

.custom-select::-ms-expand {
    display: none;
}

.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
}

.custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    overflow: hidden;
    opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Browse';
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: 'Browse';
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-range:focus {
    outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow:
        0 0 0 1px #fff,
        0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow:
        0 0 0 1px #fff,
        0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
    box-shadow:
        0 0 0 1px #fff,
        0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
    box-shadow:
        0 0 0 1px #fff,
        0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none;
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -moz-transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none;
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -ms-transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none;
    }
}

.custom-range::-ms-thumb:active {
    background-color: #b3d7ff;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
    cursor: default;
}

.custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    -webkit-transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        -webkit-transition: none;
        transition: none;
    }
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.card > hr {
    margin-right: 0;
    margin-left: 0;
}

.card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
}

.card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
    border-top: 0;
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link + .card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .card-deck .card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group > .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .card-group > .card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion {
    overflow-anchor: none;
}

.accordion > .card {
    overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px;
}

.breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: '>';
}

.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #6c757d;
}

.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:focus {
    box-shadow: none !important;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    -webkit-transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        -webkit-transition: none;
        transition: none;
    }
}

a.badge:hover,
a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #007bff;
}

a.badge-primary:hover,
a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc;
}

a.badge-primary:focus,
a.badge-primary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
    color: #fff;
    background-color: #6c757d;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
    color: #fff;
    background-color: #28a745;
}

a.badge-success:hover,
a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34;
}

a.badge-success:focus,
a.badge-success.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
    color: #fff;
    background-color: #17a2b8;
}

a.badge-info:hover,
a.badge-info:focus {
    color: #fff;
    background-color: #117a8b;
}

a.badge-info:focus,
a.badge-info.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
    color: #212529;
    background-color: #ffc107;
}

a.badge-warning:hover,
a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00;
}

a.badge-warning:focus,
a.badge-warning.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
    color: #fff;
    background-color: #dc3545;
}

a.badge-danger:hover,
a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130;
}

a.badge-danger:focus,
a.badge-danger.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
    color: #fff;
    background-color: #343a40;
}

a.badge-dark:hover,
a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124;
}

a.badge-dark:focus,
a.badge-dark.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 4rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.alert-primary hr {
    border-top-color: #9fcdff;
}

.alert-primary .alert-link {
    color: #002752;
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.alert-secondary hr {
    border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
    color: #202326;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-success hr {
    border-top-color: #b1dfbb;
}

.alert-success .alert-link {
    color: #0b2e13;
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.alert-info hr {
    border-top-color: #abdde5;
}

.alert-info .alert-link {
    color: #062c33;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.alert-warning hr {
    border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
    color: #533f03;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert-danger hr {
    border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
    color: #491217;
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
}

.alert-light hr {
    border-top-color: #ececf6;
}

.alert-light .alert-link {
    color: #686868;
}

.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
}

.alert-dark hr {
    border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
    color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

.progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        -webkit-transition: none;
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none;
    }
}

.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.list-group-item + .list-group-item {
    border-top-width: 0;
}

.list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.list-group-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
    margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal-sm > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-sm > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-sm > .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-sm > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal-md > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-md > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-md > .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-md > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal-lg > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-lg > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-lg > .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-lg > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal-xl > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xl > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-xl > .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xl > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.list-group-flush {
    border-radius: 0;
}

.list-group-flush > .list-group-item {
    border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: #004085;
    background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085;
}

.list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41;
}

.list-group-item-success {
    color: #155724;
    background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724;
}

.list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460;
}

.list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404;
}

.list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24;
}

.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
}

.list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: 0.75;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}

a.close.disabled {
    pointer-events: none;
}

.toast {
    -ms-flex-preferred-size: 350px;
    flex-basis: 350px;
    max-width: 350px;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    opacity: 0;
    border-radius: 0.25rem;
}

.toast:not(:last-child) {
    margin-bottom: 0.75rem;
}

.toast.showing {
    opacity: 1;
}

.toast.show {
    display: block;
    opacity: 1;
}

.toast.hide {
    display: none;
}

.toast-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
    padding: 0.75rem;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
    padding-top: 50px;
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition:
        transform 0.3s ease-out,
        -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none;
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
    z-index: 1050;
}

.modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
    margin: 0.25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }

    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
    }

    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: '';
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top'] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow {
    bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right'] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom'] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow {
    top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left'] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^='top'] {
    margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^='top'] > .arrow {
    bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^='top'] > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^='right'] {
    margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^='right'] > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom'] {
    margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^='bottom'] > .arrow {
    top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: '';
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^='left'] {
    margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^='left'] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^='left'] > .arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: '';
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition:
        transform 0.6s ease-in-out,
        -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        -webkit-transition: none;
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

.carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0s 0.6s;
    transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        -webkit-transition: none;
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    -webkit-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
        -webkit-transition: none;
        transition: none;
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        -webkit-transition: none;
        transition: none;
    }
}

.carousel-indicators .active {
    opacity: 1;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: 0.75s linear infinite spinner-grow;
    animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
    .spinner-border,
    .spinner-grow {
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s;
    }
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #007bff !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #0062cc !important;
}

.bg-secondary {
    background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #545b62 !important;
}

.bg-success {
    background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #1e7e34 !important;
}

.bg-info {
    background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #117a8b !important;
}

.bg-warning {
    background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #d39e00 !important;
}

.bg-danger {
    background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #bd2130 !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #dae0e5 !important;
}

.bg-dark {
    background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #1d2124 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #dee2e6 !important;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
    border-left: 1px solid #dee2e6 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #007bff !important;
}

.border-secondary {
    border-color: #6c757d !important;
}

.border-success {
    border-color: #28a745 !important;
}

.border-info {
    border-color: #17a2b8 !important;
}

.border-warning {
    border-color: #ffc107 !important;
}

.border-danger {
    border-color: #dc3545 !important;
}

.border-light {
    border-color: #f8f9fa !important;
}

.border-dark {
    border-color: #343a40 !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded-sm {
    border-radius: 0.2rem !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
    border-radius: 0.3rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: '';
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }

    .float-sm-right {
        float: right !important;
    }

    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }

    .float-md-right {
        float: right !important;
    }

    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }

    .float-lg-right {
        float: right !important;
    }

    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }

    .float-xl-right {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important;
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: '';
    background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #fff !important;
}

.text-primary {
    color: #007bff !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #0056b3 !important;
}

.text-secondary {
    color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #494f54 !important;
}

.text-success {
    color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
    color: #19692c !important;
}

.text-info {
    color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #0f6674 !important;
}

.text-warning {
    color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #ba8b00 !important;
}

.text-danger {
    color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #a71d2a !important;
}

.text-light {
    color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #cbd3da !important;
}

.text-dark {
    color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #121416 !important;
}

.text-body {
    color: #212529 !important;
}

.text-muted {
    color: #6c757d !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important;
    word-wrap: break-word !important;
}

.text-reset {
    color: inherit !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

@media print {
    *,
    *::before,
    *::after {
        text-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    abbr[title]::after {
        content: ' (' attr(title) ')';
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    @page {
        size: a3;
    }

    body {
        min-width: 992px !important;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }

    .badge {
        border: 1px solid #000;
    }
}

.select2-container {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
}

.select2-container .select2-selection--single {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
    background-color: transparent;
    border: none;
    font-size: 1em;
}

.select2-container[dir='rtl'] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px;
}

.select2-container .select2-selection--multiple {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline;
    list-style: none;
    padding: 0;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
    background-color: transparent;
    border: none;
    font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    margin-left: 5px;
    padding: 0;
    max-width: 100%;
    resize: none;
    height: 18px;
    vertical-align: bottom;
    font-family: sans-serif;
    overflow: hidden;
    word-break: keep-all;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.select2-dropdown {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051;
}

.select2-results {
    display: block;
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
}

.select2-results__option {
    padding: 6px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
}

.select2-results__option--selectable {
    cursor: pointer;
}

.select2-container--open .select2-dropdown {
    left: 0;
}

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-search--dropdown {
    display: block;
    padding: 4px;
}

.select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
    display: none;
}

.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
    filter: alpha(opacity=0);
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    height: 26px;
    margin-right: 20px;
    padding-right: 0px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}

.select2-container--default[dir='rtl'] .select2-selection--single .select2-selection__clear {
    float: left;
}

.select2-container--default[dir='rtl'] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    padding-bottom: 5px;
    padding-right: 5px;
    position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
    padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    font-weight: bold;
    height: 20px;
    margin-right: 10px;
    margin-top: 5px;
    position: absolute;
    right: 0;
    padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    padding: 0;
    padding-left: 20px;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
    cursor: default;
    padding-left: 2px;
    padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    background-color: transparent;
    border: none;
    border-right: 1px solid #aaa;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #999;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    padding: 0 4px;
    position: absolute;
    left: 0;
    top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
    background-color: #f1f1f1;
    color: #333;
    outline: none;
}

.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto;
}

.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice__display {
    padding-left: 5px;
    padding-right: 2px;
}

.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice__remove {
    border-left: 1px solid #aaa;
    border-right: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__clear {
    float: left;
    margin-left: 10px;
    margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: solid black 1px;
    outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em;
}

.select2-container--default
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option {
    margin-left: -3em;
    padding-left: 4em;
}

.select2-container--default
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option {
    margin-left: -4em;
    padding-left: 5em;
}

.select2-container--default
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option {
    margin-left: -5em;
    padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
    padding: 0;
}

.select2-container--default .select2-results__option--disabled {
    color: #999;
}

.select2-container--default .select2-results__option--selected {
    background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #5897fb;
    color: white;
}

.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
}

/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

.input-append.date .add-on,
.input-prepend.date .add-on {
    cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
    margin-top: 3px;
}

.input-daterange input {
    text-align: center;
}

.input-daterange input:first-child {
    border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
    border-radius: 0 3px 3px 0;
}

.input-daterange .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 18px;
    padding: 4px 5px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    vertical-align: middle;
    background-color: #eee;
    border: 1px solid #ccc;
    margin-left: -5px;
    margin-right: -5px;
}

@-webkit-keyframes passing-through {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }

    30%,
    70% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }
}

@keyframes passing-through {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }

    30%,
    70% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }
}

@-webkit-keyframes slide-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }

    30% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }

    30% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    10% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    20% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    10% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    20% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.dropzone,
.dropzone * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.dropzone {
    min-height: 150px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: white;
    padding: 20px 20px;
}

.dropzone.dz-clickable {
    cursor: pointer;
}

.dropzone.dz-clickable * {
    cursor: default;
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
    cursor: pointer;
}

.dropzone.dz-started .dz-message {
    display: none;
}

.dropzone.dz-drag-hover {
    border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
    opacity: 0.5;
}

.dropzone .dz-message {
    text-align: center;
    margin: 2em 0;
}

.dropzone .dz-message .dz-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.dropzone .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px;
}

.dropzone .dz-preview:hover {
    z-index: 1000;
}

.dropzone .dz-preview:hover .dz-details {
    opacity: 1;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
    border-radius: 20px;
    background: #999;
    background: -webkit-gradient(linear, left top, left bottom, from(#eee), to(#ddd));
    background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone .dz-preview.dz-file-preview .dz-details {
    opacity: 1;
}

.dropzone .dz-preview.dz-image-preview {
    background: white;
}

.dropzone .dz-preview.dz-image-preview .dz-details {
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.dropzone .dz-preview .dz-remove {
    font-size: 14px;
    text-align: center;
    display: block;
    cursor: pointer;
    border: none;
}

.dropzone .dz-preview .dz-remove:hover {
    text-decoration: underline;
}

.dropzone .dz-preview .dz-details {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 13px;
    min-width: 100%;
    max-width: 100%;
    padding: 2em 1em;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    line-height: 150%;
}

.dropzone .dz-preview .dz-details .dz-size {
    margin-bottom: 1em;
    font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
    white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
    border: 1px solid rgba(200, 200, 200, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
    border: 1px solid transparent;
}

.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0 0.4em;
    border-radius: 3px;
}

.dropzone .dz-preview:hover .dz-image img {
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    -webkit-filter: blur(8px);
    filter: blur(8px);
}

.dropzone .dz-preview .dz-image {
    border-radius: 20px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    position: relative;
    display: block;
    z-index: 10;
}

.dropzone .dz-preview .dz-image img {
    display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
    -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-error .dz-error-mark {
    opacity: 1;
    -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
    pointer-events: none;
    opacity: 0;
    z-index: 500;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -27px;
}

.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
    display: block;
    width: 54px;
    height: 54px;
}

.dropzone .dz-preview.dz-processing .dz-progress {
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-in;
    transition: opacity 0.4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
    -webkit-animation: pulse 6s ease infinite;
    animation: pulse 6s ease infinite;
}

.dropzone .dz-preview .dz-progress {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    height: 16px;
    left: 50%;
    top: 50%;
    margin-top: -8px;
    width: 80px;
    margin-left: -40px;
    background: rgba(255, 255, 255, 0.9);
    -webkit-transform: scale(1);
    border-radius: 8px;
    overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
    background: #333;
    background: -webkit-gradient(linear, left top, left bottom, from(#666), to(#444));
    background: linear-gradient(to bottom, #666, #444);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    -webkit-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
}

.dropzone .dz-preview.dz-error .dz-error-message {
    display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
    opacity: 1;
    pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
    pointer-events: none;
    z-index: 1000;
    position: absolute;
    display: block;
    display: none;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    border-radius: 8px;
    font-size: 13px;
    top: 130px;
    left: -10px;
    width: 140px;
    background: #be2626;
    background: -webkit-gradient(linear, left top, left bottom, from(#be2626), to(#a92222));
    background: linear-gradient(to bottom, #be2626, #a92222);
    padding: 0.5em 1.2em;
    color: white;
}

.dropzone .dz-preview .dz-error-message:after {
    content: '';
    position: absolute;
    top: -6px;
    left: 64px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #be2626;
}

/**
 * ----------------------------------------
 * animation sidebar slide-right
 * ----------------------------------------
 */
.sb-slide-right {
    -webkit-animation: sb-slide-right 1s cubic-bezier(0.895, 0.03, 0.685, 0.22) both;
    animation: sb-slide-right 1s cubic-bezier(0.895, 0.03, 0.685, 0.22) both;
}

@-webkit-keyframes sb-slide-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-125px);
        transform: translateX(-125px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes sb-slide-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-125px);
        transform: translateX(-125px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

/**
 * ----------------------------------------
 * animation head slide-bottom
 * ----------------------------------------
 */
.h-slide-bottom {
    -webkit-animation: h-slide-bottom 1s cubic-bezier(0.895, 0.03, 0.685, 0.22) both;
    animation: h-slide-bottom 1s cubic-bezier(0.895, 0.03, 0.685, 0.22) both;
}

@-webkit-keyframes h-slide-bottom {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-57px);
        transform: translateY(-57px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes h-slide-bottom {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-57px);
        transform: translateY(-57px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

/**
 * ----------------------------------------
 * animation container slide-top
 * ----------------------------------------
 */
.c-slide-top {
    -webkit-animation: c-slide-top 1s cubic-bezier(0.895, 0.03, 0.685, 0.22) both;
    animation: c-slide-top 1s cubic-bezier(0.895, 0.03, 0.685, 0.22) both;
}

@-webkit-keyframes c-slide-top {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes c-slide-top {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
/* .fade-in {
    -webkit-animation: fade-in 0.8s cubic-bezier(0.895, 0.03, 0.685, 0.22) both;
    animation: fade-in 0.8s cubic-bezier(0.895, 0.03, 0.685, 0.22) both;
} */

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
.attention-animate {
    -webkit-animation: attention-animate 1s 1.2s both;
    animation: attention-animate 1s 1.2s both;
}

@-webkit-keyframes attention-animate {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    20% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    30% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    45% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }

    55% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }

    65% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes attention-animate {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    20% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    30% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    45% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }

    55% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }

    65% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

img {
    vertical-align: middle;
}

a {
    color: #007bff;
}

a:hover,
a:focus,
a:active {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: none;
}

ul,
ol {
    list-style: none;
    padding: 0;
}

.okc {
    position: relative;
    background: #f8fafc;
    max-width: 1920px;
}

.okc-container {
    padding: 16.5px 25px;
}

.okc-card {
    border: 1px solid #cacaca;
    border-radius: 4px;
    /* overflow: hidden; */
    margin-bottom: 25px;
}

.okc-card__row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    padding: 24px 30px;
}

.okc-card__row [class*='col'] {
    padding: 0;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.okc-card__row label {
    margin: 0;
}

.okc-card__row-error {
    border-radius: 4px;
    border: 1px solid #eb2013;
}

.okc-card__body {
    background: #fff;
}

.okc-card__body ~ .okc-card__footer .icon-next {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.okc-card__body.show ~ .okc-card__footer .icon-next {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.okc-card__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 22px 30px;
    background: #f0f0f0;
    font-size: 20px;
    font-weight: 700;
}

.okc-card__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 22px;
    background: #f0f0f0;
}

.okc-title__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 16.5px;
}

.okc-title__wrapper .okc-title {
    margin-bottom: 0;
    margin-right: auto;
}

.okc-title {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16.5px;
}

.okc-error {
    font-size: 12px;
    color: #eb2013;
    text-align: left;
}

.okc-note {
    font-size: 12px;
    text-align: left;
}

.okc-panel {
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 11px 13px;
    border-radius: 4px;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    position: relative;
    overflow: hidden;
}

.okc-panel:after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 10%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.okc-panel:hover:after {
    width: 100%;
    opacity: 1;
}

.okc-panel:after {
    z-index: 1;
    background: rgba(0, 0, 0, 0.05);
}

.okc-panel__icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 18px;
    flex: 0 0 18px;
    font-size: 18px;
    color: #afafaf;
    margin-right: 12px;
    z-index: 2;
}

.okc-panel:hover .okc-panel__icon {
    color: #ef7a00;
}

.v-sidebar {
}

.okc-header {
    position: sticky;
    top: 0;
    width: 100%;
    height: 57px;
    background: #fff;
    z-index: 3000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
}

.okc-header__sidebar-trigger {
    position: relative;
    overflow: hidden;
}

.okc-header__sidebar-trigger:after {
    position: absolute;
    display: block;
    content: '';
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.okc-header__sidebar-trigger:hover:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.okc-header__sidebar-trigger.btn--square {
    width: 57px;
    height: 57px;
    border-radius: 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
}

.okc-header__sidebar-trigger.btn.btn--square:after {
    background: rgba(0, 0, 0, 0.1);
    color: #d66f00;
}

.okc-header__breadcrumb,
.okc-header__company {
    margin-left: 10px;
    margin-right: auto;
}

.okc-header__company {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    position: sticky;
    top: 0px;
}

.okc-left {
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    height: 100vh;
    padding: 0;
    left: 0px;
}

.okc-right {
    width: calc(100% - 74px);
    overflow: auto;
    height: 100vh;
}

.white-space-pre {
    white-space: pre;
}

.price-registration-modal table thead tr th,
.price-registration-modal table tbody tr th {
    border-top-color: #e1e6ea;
    background-color: #f0f0f0;
}

.price-registration-modal table .cell-sm {
    width: 110px;
    min-width: 110px;
}

.price-registration-modal table .cell {
    width: 180px;
    min-width: 180px;
}

.off-hover-effect th:after,
.off-hover-effect td:after {
    display: none !important;
}

@font-face {
    font-family: 'okicon';
    src:
        url('./assets/fonts/okicon.ttf?yjjv7v') format('truetype'),
        url('./assets/fonts/okicon.ttf?yjjv7v') format('woff'),
        url('./assets/fonts/okicon.ttf?yjjv7v#okicon') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'okicon' !important;
    speak: never;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
    content: '';
}

.icon-filter:before {
    content: '';
}

.icon-check:before {
    content: '';
}

.icon-prev:before {
    content: '';
}

.icon-next:before {
    content: '';
}

.icon-close:before {
    content: '';
}

.icon-user:before {
    content: '';
}

.icon-circle:before {
    content: '';
}

.icon-exit:before {
    content: '';
}

.icon-menu:before {
    content: '';
}

.icon-drive:before {
    content: '';
}

.icon-bin:before {
    content: '';
}

.icon-upload:before {
    content: '';
}

.icon-edit:before {
    content: '';
}

.icon-plus:before {
    content: '';
}

.icon-image {
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    position: relative;
}

.btn--secondary {
    color: #555;
    background: #ddd;
}

.btn--secondary:hover {
    color: #000;
}

.btn--sm {
    font-size: 12px;
    padding: 4px;
    min-width: 60px;
}

.btn--lg {
    font-size: 16px !important;
    padding: 10px 40px !important;
}

.btn--xl {
    padding: 15px 90px;
    font-size: 18px;
}

.btn--icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
}

.btn:disabled,
.btn--disabled {
    cursor: not-allowed !important;
    opacity: 0.75;
}

.custom-breadcrumb .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;
}

.custom-breadcrumb__item + .custom-breadcrumb__item {
    padding-left: 10px;
}

.custom-breadcrumb__item + .custom-breadcrumb__item:before {
    color: #000;
    padding-right: 10px;
}

.custom-breadcrumb__item.active .custom-breadcrumb__link {
    color: #ef7a00;
}

.custom-breadcrumb__item.active .custom-breadcrumb__link:hover {
    color: #d66f00;
}

.custom-breadcrumb__link {
    display: inline-block;
    font-size: 12px;
    color: #000;
    -webkit-transition: color 0.15s ease;
    transition: color 0.15s ease;
    white-space: nowrap;
}

.custom-breadcrumb__link:hover {
    color: #d66f00;
}

.okc-modal__dialog {
    border: 0;
    border-radius: 8px;
}

@media (min-width: 576px) {
    .okc-modal__dialog {
        max-width: 692px;
    }
}

@media (min-width: 576px) {
    .okc-modal__dialog--md {
        max-width: 1000px;
    }
}

@media (min-width: 576px) {
    .okc-modal__dialog--lg {
        max-width: 1200px;
    }
}

@media (min-width: 576px) {
    .okc-modal__dialog--xl {
        max-width: 1400px;
    }
}

.okc-modal__content {
    border: 0;
    -webkit-box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);
}

.okc-modal__header {
    background: #ef7a00;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    padding: 12px;
    position: relative;
}

.okc-modal__header .modal-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: block;
    text-align: center;
}

.okc-modal__header .close {
    width: 20px;
    height: 20px;
    color: #fff;
    position: absolute;
    top: 50%;
    right: 12px;
    font-size: 10px;
    -webkit-transition: color 0.15s ease;
    transition: color 0.15s ease;
    opacity: 1;
    padding: 0;
    margin: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.okc-modal__header .close:not(:disabled):not(.disabled):hover,
.okc-modal__header .close:not(:disabled):not(.disabled):focus {
    color: #b36109;
    opacity: 1;
    text-shadow: none;
}

.okc-modal__body {
    padding: 40px 80px 20px;
}

.okc-modal__button-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.okc-modal__footer {
    border: 0;
    padding: 20px 80px 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.okc-modal__footer .btn {
    width: 200px;
}

.okc-modal__footer .btn:not(:last-child) {
    margin-right: 50px;
}

.okc-pagination {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-bottom: 18px;
}

.okc-pagination--total {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.okc-pagination__total {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.okc-pagination .pagination {
    margin-bottom: 0;
}

.okc-pagination .page-item {
    margin: 0 4px;
    cursor: pointer;
    z-index: 0;
}

.okc-pagination .page-item:first-child {
    margin-left: 0;
}

.okc-pagination .page-item:last-child {
    margin-right: 0;
}

.okc-pagination .page-item .page-link {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 0;
    color: #555;
    background: #ddd;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    padding: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.okc-pagination .page-item .page-link:after {
    position: absolute;
    display: block;
    content: '';
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.okc-pagination .page-item .page-link:hover:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.okc-pagination .page-item .page-link:after {
    z-index: 1;
}

.okc-pagination .page-item .page-link:hover {
    color: #000;
}

.okc-pagination .page-item.active .page-link,
.okc-pagination .page-item.active:hover .page-link {
    color: #fff;
    background: #d66f00;
}

.okc-pagination .page-item.active .page-link:after,
.okc-pagination .page-item.active:hover .page-link:after {
    background: rgba(255, 255, 255, 0.2);
}

.okc-pagination .page-item.active .page-link:hover,
.okc-pagination .page-item.active:hover .page-link:hover {
    background: rgba(214, 111, 0, 0.7);
}

.okc-pagination .page-item.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.form-group.form-group__select {
    margin-bottom: 0;
}

.form-group.form-group__select .form-select__label {
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 10px;
}

.form-group.form-group__select:not(:last-child) {
    margin-bottom: 25px;
}

/* stylelint-disable */
.select2-container.select2-container--default .select2-selection {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #e1e6ea;
    background: #fff;
    color: #000;
    font-size: 13px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 26px 0 16px;
}

.select2-container.select2-container--default .select2-selection__arrow {
    height: 100%;
    right: 0;
}

.select2-container.select2-container--default .select2-selection__arrow b {
    border-top-color: #000;
    left: 0;
}

.select2-container.select2-container--default .select2-selection__rendered {
    padding: 0;
}

.select2-container.select2-container--default .select2-selection.select2-selection--multiple {
    display: block;
    height: auto;
    padding: 10px 16px 1px;
}

.select2-container--default .select2-selection--multiple:before {
    content: ' ';
    display: block;
    position: absolute;
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    right: 15px;
    margin-left: -4px;
    margin-top: -2px;
    top: 50%;
    width: 0;
    cursor: pointer;
    border-top-color: #000;
}

.select2-container--open .select2-selection--multiple:before {
    content: ' ';
    display: block;
    position: absolute;
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
    height: 0;
    right: 15px;
    margin-left: -4px;
    margin-top: -2px;
    top: 50%;
    width: 0;
    cursor: pointer;
    border-top-color: #000;
    border-bottom-color: #d66f00;
}

.select2-container.select2-container--default .select2-selection.select2-selection--multiple .select2-selection__rendered {
    margin-bottom: 5px;
}

.select2-container.select2-container--default .select2-selection.select2-selection--multiple .select2-search__field {
    height: 19px;
    border: none;
    margin: 0 0 5px;
}

.select2-container.select2-container--default .select2-selection.select2-selection--multiple .select2-selection__choice {
    margin: 0;
    padding: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    background: #fff;
    border: 1px solid #d66f00;
    margin-bottom: 5px;
    margin-right: 5px;
}

.select2-container.select2-container--default .select2-selection.select2-selection--multiple .select2-selection__choice__remove {
    position: relative;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 0;
    border-left: 1px solid #d66f00;
    background: #d66f00;
    color: #fff;
}

.select2-container.select2-container--default .select2-selection.select2-selection--multiple .select2-selection__choice__display {
    font-size: 12px;
    padding: 2px 5px;
    color: #d66f00;
    font-weight: 600;
}

.select2-container.select2-container--default.select2-container--open .select2-selection,
.select2-container.select2-container--default.select2-container--focus .select2-selection {
    -webkit-box-shadow: 0;
    box-shadow: 0;
    outline: 0;
}

.select2-container.select2-container--default.select2-container--focus .select2-selection {
    border-color: #d66f00;
}

.select2-container.select2-container--default.select2-container--below.select2-container--open .select2-selection {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-color: #d66f00;
    border-right-color: #d66f00;
    border-bottom-color: transparent;
    border-left-color: #d66f00;
}

.select2-container.select2-container--default.select2-container--above.select2-container--open .select2-selection {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-color: transparent;
    border-right-color: #d66f00;
    border-bottom-color: #d66f00;
    border-left-color: #d66f00;
}

.select2-container.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-bottom-color: #d66f00;
}

.select2-container.select2-container--default.select2-container--disabled .select2-selection--single {
    color: #000;
    background: #f0f0f0;
    cursor: not-allowed;
}

.select2-container.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__arrow b {
    border-top-color: #f8fafc;
}

.select2-container.select2-container--default .select2-dropdown.select2-dropdown--below,
.select2-container.select2-container--default .select2-dropdown.select2-dropdown--above {
    border: 1px solid #d66f00;
    overflow: hidden;
}

.select2-container.select2-container--default .select2-results__option {
    padding: 6px 16px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.select2-container.select2-container--default .select2-results__option:after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 10%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.select2-container.select2-container--default .select2-results__option:hover:after {
    width: 100%;
    opacity: 1;
}

.select2-container.select2-container--default .select2-results__option:hover {
    color: #ef7a00;
}

.select2-container.select2-container--default .select2-results__option[aria-disabled='true'] {
    cursor: not-allowed;
    color: #e1e6ea;
}

.select2-container.select2-container--default .select2-results__option--highlighted {
    background: rgba(239, 122, 0, 0.2);
    color: #ef7a00;
}

.select2-container.select2-container--default .select2-results__option--selected {
    background: #ef7a00 !important;
    color: #fff !important;
}

.select2-container.select2-container--default .select2-results__option:after {
    width: 85%;
    background: rgba(239, 122, 0, 0.2);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: 0;
    z-index: 1;
}

.select2-container.select2-container--default .select2-search--dropdown {
    padding: 7px 16px;
}

.select2-container.select2-container--default .select2-search__field {
    border: 1px solid #e1e6ea;
}

.select2-container.select2-container--default .select2-search__field:focus {
    outline: none;
    border-color: #d66f00;
}

.select2-container.select2-container--default .select2-selection__placeholder {
    color: #afafaf;
}

.select2--error ~ .select2-container.select2-container--default .select2-selection {
    border-color: #eb2013 !important;
}

.select2--error ~ .select2-container.select2-container--default.select2-container--above .select2-selection[aria-expanded='true'] {
    border-top-color: transparent !important;
}

.select2--error ~ .select2-container.select2-container--default.select2-container--below .select2-selection[aria-expanded='true'] {
    border-bottom-color: transparent !important;
}

.select2-container.select2-container--default .select2-dropdown.select2--error {
    border-color: #eb2013 !important;
}

/* stylelint-enable */
.form-group__label {
    color: #000;
    font-size: 13px;
}

.form-control--input {
    border-radius: 4px;
    border: 1px solid #e1e6ea;
    color: #000;
    font-size: 13px;
    padding: 16px;
    height: 40px;
}

.form-control--input.focus,
.form-control--input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    border-color: #d66f00;
}

.form-control--input::-webkit-input-placeholder {
    color: #afafaf;
}

.form-control--input::-moz-placeholder {
    color: #afafaf;
}

.form-control--input:-ms-input-placeholder {
    color: #afafaf;
}

.form-control--input::-ms-input-placeholder {
    color: #afafaf;
}

.form-control--input::placeholder {
    color: #afafaf;
}

.form-control--error,
.form-control--error.focus,
.form-control--error:focus {
    border-color: #eb2013;
}

.okc-upload {
    width: 100%;
    margin-bottom: 50px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #fff;
}

.okc-upload .dropzone {
    border: 2px dashed #d66f00;
    min-height: 100px;
    width: 100%;
    padding: 50px;
}

.okc-upload .dropzone--single {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.okc-upload--error .dropzone {
    border-color: #eb2013;
}

.okc-upload--error .okc-error.d-none {
    display: block !important;
}

.custom-checkbox {
    padding-left: 0;
}

.custom-checkbox .form-check-label {
    color: #000;
    cursor: pointer;
    padding-left: 30px;
    position: relative;
    white-space: nowrap;
}

.custom-checkbox .form-check-label:before {
    z-index: 1;
    content: '';
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 12px;
    border: 1px solid #000;
    color: #d66f00;
    border-radius: 4px;
    background-color: #fff;
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'okicon' !important;
    speak: never;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
}

.custom-checkbox .form-check-label:after {
    z-index: 2;
    background-color: #fff;
    width: 16px;
    height: 16px;
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
}

.custom-checkbox .form-check-input {
    margin: 0;
    position: absolute;
    z-index: -1;
}

.custom-checkbox .form-check-input:disabled {
    opacity: 0;
}

.custom-checkbox .form-check-input:checked ~ .form-check-label:before {
    border-color: #d66f00;
}

.custom-checkbox .form-check-input:checked ~ .form-check-label:after {
    left: 18px;
    width: 0;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
}

.custom-checkbox .form-check-input[disabled] ~ .form-check-label {
    color: #ccc;
}

.custom-checkbox .form-check-input[disabled] ~ .form-check-label:before {
    border-color: #ccc;
    background: #ccc;
    color: #ccc;
}

.custom-checkbox .form-check-input[disabled] ~ .form-check-label:after {
    display: none;
}

.custom-checkbox .form-check-input:checked[disabled] ~ .form-check-label {
    color: #ccc;
}

.custom-checkbox .form-check-input:checked[disabled] ~ .form-check-label:before {
    border-color: #ccc;
    background: #ccc;
    color: #fff;
}

.custom-checkbox .form-check-input:checked[disabled] ~ .form-check-label:after {
    display: none;
}

.custom-radio .form-check-label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

.custom-radio .form-check-label:before {
    background-color: #d66f00;
    opacity: 0;
    -webkit-transform: translateY(-50%) scale(0.1);
    transform: translateY(-50%) scale(0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: 2;
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 1px);
    left: 3px;
}

.custom-radio .form-check-label:after {
    background-color: #fff;
    border: 1px solid #000;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: 1;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
}

.custom-radio .form-check-label span {
    display: block;
}

.custom-radio .form-check-input {
    margin: 0;
    position: absolute;
    z-index: -1;
}

.custom-radio .form-check-input:checked ~ .form-check-label:before {
    opacity: 1;
    -webkit-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
}

.custom-radio .form-check-input:checked ~ .form-check-label:after {
    border-color: #d66f00;
}

.custom-radio .form-check-input[disabled] ~ .form-check-label {
    color: #fff;
}

.custom-radio .form-check-input[disabled] ~ .form-check-label:before {
    display: none;
}

.custom-radio .form-check-input[disabled] ~ .form-check-label:after {
    border-color: #fff;
}

.custom-radio .form-check-input:checked[disabled] ~ .form-check-label:before {
    background: #fff;
    display: block;
}
/* stylelint-enable */
.form-textarea {
    width: 100%;
}

.form-textarea .form-control {
    padding: 16px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #e1e6ea;
    min-height: 100px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 13px;
}

.form-textarea .form-control:focus {
    border: 1px solid #d66f00;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-textarea .form-control::-webkit-input-placeholder {
    color: #afafaf;
}

.form-textarea .form-control::-moz-placeholder {
    color: #afafaf;
}

.form-textarea .form-control:-ms-input-placeholder {
    color: #afafaf;
}

.form-textarea .form-control::-ms-input-placeholder {
    color: #afafaf;
}

.form-textarea .form-control::placeholder {
    color: #afafaf;
}

.okc-badge {
    border: 2px solid white;
    font-size: 10px;
    background: #d66f00;
    color: #fff;
    max-width: 50px;
    overflow: hidden;
}

.price-registration .okc-result-form__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #f0f0f0;
    border-radius: 4px;
    font-weight: 700;
    padding: 5px 20px;
    font-size: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.price-registration .okc-result-form__title p {
    margin: 0;
}

@media (max-width: 991.98px) {
    .price-registration .okc-result-form__title p {
        margin-bottom: 10px;
    }
}

.price-registration .okc-result-form__blank {
    height: 40px;
}

.price-registration .okc-result-form .okc-card__row {
    padding: 24px 0;
    margin: 0 30px;
}

.price-registration .okc-result-form .okc-card__row:not(:last-child) {
    border-bottom: 1px solid #cacaca;
}

.price-registration .okc-result-form .form-check.custom-radio {
    /* min-width: 165px; */
    width: 165px;
    border-radius: 4px;
    /* padding: 0; */
    padding: 1px;
    position: relative;
    overflow: hidden;
}

.price-registration .okc-result-form .form-check.custom-radio:after {
    position: absolute;
    display: block;
    content: '';
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.price-registration .okc-result-form .form-check.custom-radio:hover:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.price-registration .okc-result-form .form-check.custom-radio .form-check-label {
    width: 100%;
    white-space: nowrap;
    padding: 13px 10px 13px 40px;
    z-index: 2;
    line-height: 14px;
    border: 1px solid #fff;
    border-radius: 4px;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
}

.price-registration .search-form .form-check.custom-radio .form-check-label {
    padding: 5px 10px 5px 40px;
}

.price-registration .okc-result-form .form-check.custom-radio .form-check-label:before {
    left: 15px;
    top: 50%;
}

.price-registration .search-form .form-check.custom-radio .form-check-label:before {
    left: 5px;
}

.price-registration .okc-result-form .form-check.custom-radio .form-check-label:after {
    top: 20px;
    left: 12px;
}

.price-registration .search-form .form-check.custom-radio .form-check-label:after {
    top: 12px;
    left: 2px;
}

.price-registration .okc-result-form .form-check.custom-radio .form-check-label:hover {
    border: 1px solid rgba(0, 0, 0, 0.01);
}

.price-registration .okc-result-form .form-check.custom-radio .form-check-input:focus ~ .form-check-label {
    border: 1px solid #d66f00;
}

.price-registration .okc-result-form .form-check.custom-radio:after {
    z-index: 1;
}

.price-registration .okc-result-form .form-check.custom-checkbox {
    min-width: 180px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

.price-registration .okc-result-form .form-check.custom-checkbox:after {
    position: absolute;
    display: block;
    content: '';
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.price-registration .okc-result-form .form-check.custom-checkbox:hover:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.price-registration .okc-result-form .form-check.custom-checkbox .form-check-label {
    width: 100%;
    white-space: normal;
    padding: 13px 10px 13px 40px;
    z-index: 2;
    line-height: 14px;
    border: 1px solid #fff;
    border-radius: 4px;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
}

.price-registration .okc-result-form .form-check.custom-checkbox .form-check-label:before {
    top: 10px;
    left: 10px;
}

.price-registration .okc-result-form .form-check.custom-checkbox .form-check-label:after {
    top: 12px;
    left: 12px;
}

.price-registration .okc-result-form .form-check.custom-checkbox .form-check-label:hover {
    border: 1px solid rgba(0, 0, 0, 0.01);
}

.price-registration .okc-result-form .form-check.custom-checkbox .form-check-input:focus ~ .form-check-label {
    border: 1px solid #d66f00;
}

.price-registration .okc-result-form .form-check.custom-checkbox .form-check-input:checked ~ .form-check-label:after {
    left: 28px;
}

.price-registration .okc-result-form .form-check.custom-checkbox:after {
    z-index: 1;
}

.price-registration .okc-result-form .form-control.form-control--input {
    text-align: left;
}

.price-registration .okc-result-form .form-control.form-control--input.js-datepicker {
    text-align: center;
}

.price-registration .okc-card .form-check.custom-checkbox {
    min-width: 180px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

.price-registration .okc-card .form-check.custom-checkbox:after {
    position: absolute;
    display: block;
    content: '';
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.price-registration .okc-card .form-check.custom-checkbox:hover:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.price-registration .okc-card .form-check.custom-checkbox .form-check-label {
    width: 100%;
    white-space: normal;
    padding: 13px 10px 13px 40px;
    z-index: 2;
    line-height: 14px;
}

.price-registration .okc-card .form-check.custom-checkbox .form-check-label:before {
    top: 10px;
    left: 10px;
}

.price-registration .okc-card .form-check.custom-checkbox .form-check-label:after {
    top: 12px;
    left: 12px;
}

.price-registration .okc-card .form-check.custom-checkbox .form-check-input:checked ~ .form-check-label:after {
    left: 28px;
}

.price-registration .okc-card .form-check.custom-checkbox:after {
    z-index: 1;
}

.okc-table.okc-table__parent thead tr:first-child th {
    border-left-color: transparent;
    border-right: 2px solid transparent;
    /* border-bottom: 2px solid #afafaf; */
}

.okc-table.okc-table__parent thead tr:last-child th {
    border-top: 2px solid #afafaf;
}

.okc-table.okc-table__parent thead tr:last-child th:last-child {
    border-right: 2px solid #afafaf;
}

.okc-table.okc-table__parent tbody tr td:last-child {
    border-right: 2px solid #afafaf;
}

.okc-table.okc-table__parent tbody tr:last-child td {
    border-bottom: 2px solid #afafaf;
}

.okc-table.okc-table__parent tbody .selectable td:first-child {
    border-left: 3px solid #d16e05;
}

.okc-table.okc-table__parent tbody .unselectable {
    cursor: not-allowed;
    opacity: 0.25;
}

.okc-table.okc-table__parent tbody .unselectable .form-check.custom-checkbox {
    cursor: not-allowed;
}

.okc-table.okc-table__parent tbody .unselectable .form-check-label {
    pointer-events: none;
}

.okc-table.okc-table__parent tbody .active {
    background: #d16e05;
    color: #fff;
}

.okc-table.okc-table__parent tbody .active .highlight {
    background: #9f5404;
}

.okc-table.okc-table__parent tbody .active .name {
    color: #fff;
}

.okc-table.okc-table__parent tbody .active:hover {
    background: #d16e05;
}

.okc-table.okc-table__parent tbody .active:hover .highlight {
    background: #9f5404;
}

.okc-table.okc-table__parent tbody .active td:after {
    background: rgba(255, 255, 255, 0.25);
}

.okc-table.okc-table__child.fade-in {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

.okc-table.okc-table__child thead tr:first-child th {
    border-left-color: transparent;
    border-right: 2px solid transparent;
    /* border-bottom: 2px solid #afafaf; */
}

.okc-table.okc-table__child thead tr:last-child th {
    border-top: 2px solid #afafaf;
}

.okc-table.okc-table__child thead tr:last-child th:first-child {
    border-left: 3px solid #afafaf;
}

.okc-table.okc-table__child thead tr:last-child th:last-child {
    border-right: 2px solid #afafaf;
}

.okc-table.okc-table__child tbody tr td:first-child {
    border-left: 3px solid #afafaf;
}

.okc-table.okc-table__child tbody tr td:last-child {
    border-right: 2px solid #afafaf;
}

.okc-table.okc-table__child tbody tr:last-child td {
    border-bottom: 2px solid #afafaf;
}

.okc-table.okc-table__child tr {
    cursor: default;
}

.okc-table.okc-table__child tr:hover {
    background: #fff !important;
}

.okc-table.okc-table__child td:after {
    display: none;
}

.okc-table.okc-table__price-regis {
    background: #f8fafc;
}

.okc-table.okc-table__price-regis thead th,
.okc-table.okc-table__price-regis thead td,
.okc-table.okc-table__price-regis tbody th,
.okc-table.okc-table__price-regis tbody td {
    width: 100px;
    min-width: 100px;
}

.okc-table.okc-table__price-regis thead th:first-child,
.okc-table.okc-table__price-regis thead td:first-child,
.okc-table.okc-table__price-regis tbody th:first-child,
.okc-table.okc-table__price-regis tbody td:first-child {
    width: 130px;
    min-width: 130px;
}

.okc-table.okc-table__price-regis thead th:nth-child(2),
.okc-table.okc-table__price-regis thead td:nth-child(2),
.okc-table.okc-table__price-regis tbody th:nth-child(2),
.okc-table.okc-table__price-regis tbody td:nth-child(2) {
    width: 300px;
    min-width: 300px;
}

.okc-table.okc-table__price-regis thead th:last-child,
.okc-table.okc-table__price-regis thead td:last-child,
.okc-table.okc-table__price-regis tbody th:last-child,
.okc-table.okc-table__price-regis tbody td:last-child {
    width: 60px;
    min-width: 60px;
}

.okc-table.okc-table__price-regis thead .form-check.custom-checkbox,
.okc-table.okc-table__price-regis tbody .form-check.custom-checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.okc-table.okc-table__price-regis thead .form-check.custom-checkbox .form-check-label,
.okc-table.okc-table__price-regis tbody .form-check.custom-checkbox .form-check-label {
    height: 20px;
    width: 100%;
    padding: 0;
}

.okc-table.okc-table__price-regis thead .form-check.custom-checkbox .form-check-label:after,
.okc-table.okc-table__price-regis tbody .form-check.custom-checkbox .form-check-label:after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.okc-table.okc-table__price-regis thead .form-check.custom-checkbox .form-check-label:before,
.okc-table.okc-table__price-regis tbody .form-check.custom-checkbox .form-check-label:before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.okc-table.okc-table__price-regis thead .form-check.custom-checkbox .form-check-input:checked ~ .form-check-label:after,
.price-registration
    .okc-table.okc-table__price-regis
    tbody
    .form-check.custom-checkbox
    .form-check-input:checked
    ~ .form-check-label:after {
    left: calc(50% + 8px);
}

.okc-table.okc-table__price-regis thead tr:first-child th {
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #afafaf;
}

.okc-table.okc-table__price-regis thead tr:last-child th {
    border-top: 2px solid #afafaf;
}

.okc-table.okc-table__price-regis thead tr:last-child th:first-child {
    border-left: 3px solid #afafaf;
}

.okc-table.okc-table__price-regis thead tr:last-child th:last-child {
    border-right: 2px solid #afafaf;
}

.okc-table.okc-table__price-regis tbody tr td:first-child {
    border-left: 3px solid #afafaf;
}

.okc-table.okc-table__price-regis tbody tr td:last-child {
    border-right: 2px solid #afafaf;
}

.okc-table.okc-table__price-regis tbody tr:last-child td {
    border-bottom: 2px solid #afafaf;
}

.okc-table.okc-table__price-regis tbody th:nth-child(4),
.okc-table.okc-table__price-regis tbody td:nth-child(4) {
    width: 60px;
    min-width: 60px;
}

.okc-table.okc-table__price-regis tbody th:nth-child(10),
.okc-table.okc-table__price-regis tbody td:nth-child(10),
.okc-table.okc-table__price-regis tbody th:nth-child(11),
.okc-table.okc-table__price-regis tbody td:nth-child(11) {
    width: 200px;
    min-width: 200px;
}

.okc-table.okc-table__price-regis .okc-table__price-regis-toggle tr:first-child {
    background: transparent;
}

.okc-table.okc-table__price-regis .okc-table__price-regis-toggle tr:first-child th {
    border: 1px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid #afafaf;
}

.okc-table.okc-table__price-regis .okc-table__price-regis-toggle tr:first-child th:nth-child(2) {
    width: 60px;
    min-width: 60px;
    padding: 5px;
    background: transparent;
}

.okc-table.okc-table__price-regis .okc-table__price-regis-toggle tr:first-child th:after {
    display: none;
}

.okc-table.okc-table__price-regis .okc-table__price-regis-toggle tr:first-child .btn {
    font-size: 10px;
    min-width: 51px;
    width: 100%;
    padding: 5px;
}

.okc-table.okc-table__price-regis .okc-table__price-regis-toggle tr:last-child th:nth-child(9),
.okc-table.okc-table__price-regis .okc-table__price-regis-toggle tr:last-child td:nth-child(9),
.okc-table.okc-table__price-regis .okc-table__price-regis-toggle tr:last-child th:nth-child(10),
.okc-table.okc-table__price-regis .okc-table__price-regis-toggle tr:last-child td:nth-child(10) {
    width: 200px;
    min-width: 200px;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd thead th,
.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd thead td,
.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd tbody th,
.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd tbody td {
    width: 50px;
    min-width: 50px;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd thead th:first-child,
.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd thead td:first-child,
.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd tbody th:first-child,
.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd tbody td:first-child {
    width: 130px;
    min-width: 130px;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd thead th:nth-child(3),
.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd thead td:nth-child(3),
.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd tbody th:nth-child(3),
.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd tbody td:nth-child(3) {
    width: 150px;
    min-width: 150px;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd thead th:last-child,
.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd thead td:last-child,
.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd tbody th:last-child,
.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd tbody td:last-child {
    width: 450px;
    min-width: 450px;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd thead tr th {
    border-bottom: 0 solid transparent;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd thead tr:first-child th {
    border-left-color: #e1e6ea;
    border-right-color: #e1e6ea;
    border-bottom-color: #afafaf;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd thead tr:last-child th {
    border-top: 2px solid #afafaf;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd thead tr:last-child th:first-child {
    border-left: 2px solid #afafaf;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd thead tr:last-child th:last-child {
    border-right: 2px solid #afafaf;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd tbody tr td:first-child {
    border-left: 2px solid #afafaf;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd tbody tr td:last-child {
    border-right: 2px solid #afafaf;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd tbody tr:last-child td {
    border-bottom: 2px solid #afafaf;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd .form-control.form-control--input {
    width: 150px;
    text-align: center;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd .form-textarea .form-control {
    padding: 10px 16px;
    min-height: 40px;
    height: 40px;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd .checkbox {
    height: 1px;
    padding: 0 !important;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd .checkbox .form-check.custom-checkbox,
.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd .checkbox .form-check-label {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100% !important;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd .checkbox .form-check-label:after {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd .select {
    min-width: 150px;
    max-width: 150px;
    width: 150px;
}

.okc-table.okc-table__price-regis.okc-table.okc-table__price-regis--2nd .select .form-control.form-control--input {
    width: 100%;
}

.price-confirm-2nd-modal thead th,
.price-confirm-2nd-modal thead td,
.price-confirm-2nd-modal tbody th,
.price-confirm-2nd-modal tbody td {
    width: 90px;
    min-width: 90px;
}

.price-confirm-2nd-modal thead th:first-child,
.price-confirm-2nd-modal thead td:first-child,
.price-confirm-2nd-modal tbody th:first-child,
.price-confirm-2nd-modal tbody td:first-child {
    width: 130px;
    min-width: 130px;
}

.price-confirm-2nd-modal thead th:nth-child(2),
.price-confirm-2nd-modal thead td:nth-child(2),
.price-confirm-2nd-modal tbody th:nth-child(2),
.price-confirm-2nd-modal tbody td:nth-child(2) {
    width: 150px;
    min-width: 150px;
}

.price-confirm-2nd-modal thead th:nth-child(5),
.price-confirm-2nd-modal thead td:nth-child(5),
.price-confirm-2nd-modal tbody th:nth-child(5),
.price-confirm-2nd-modal tbody td:nth-child(5) {
    width: 50px;
    min-width: 50px;
}

.price-confirm-2nd-modal thead th:last-child,
.price-confirm-2nd-modal thead td:last-child,
.price-confirm-2nd-modal tbody th:last-child,
.price-confirm-2nd-modal tbody td:last-child {
    width: 300px;
    min-width: 300px;
}

.price-confirm-2nd-modal thead tr th {
    background: #f0f0f0;
    border-bottom: 0 solid transparent;
}

.price-confirm-2nd-modal thead tr:first-child th {
    border-left-color: #e1e6ea;
    border-right-color: #e1e6ea;
    border-bottom-color: #afafaf;
}

.price-confirm-2nd-modal thead tr:last-child th {
    border-top: 2px solid #afafaf;
}

.price-confirm-2nd-modal thead tr:last-child th:first-child {
    border-left: 2px solid #afafaf;
}

.price-confirm-2nd-modal thead tr:last-child th:last-child {
    border-right: 2px solid #afafaf;
}

.price-confirm-2nd-modal tbody tr td:first-child {
    border-left: 2px solid #afafaf;
}

.price-confirm-2nd-modal tbody tr td:last-child {
    border-right: 2px solid #afafaf;
}

.price-confirm-2nd-modal tbody tr:last-child td {
    border-bottom: 2px solid #afafaf;
}

.price-confirm-2nd-modal .form-check.custom-checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.price-confirm-2nd-modal .form-check.custom-checkbox .form-check-label {
    height: 20px;
    width: 100%;
    padding: 0;
}

.price-confirm-2nd-modal .form-check.custom-checkbox .form-check-label:before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.competitive-store__checkboxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.competitive-store__checkboxes .form-check.custom-checkbox {
    min-width: 180px;
    position: relative;
    overflow: hidden;
}

.competitive-store__checkboxes .form-check.custom-checkbox:after {
    position: absolute;
    display: block;
    content: '';
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.competitive-store__checkboxes .form-check.custom-checkbox:hover:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.competitive-store__checkboxes .form-check.custom-checkbox .form-check-label {
    width: 100%;
    white-space: nowrap;
    padding: 10px 10px 10px 40px;
    z-index: 2;
}

.competitive-store__checkboxes .form-check.custom-checkbox .form-check-label:before {
    left: 10px;
}

.competitive-store__checkboxes .form-check.custom-checkbox .form-check-label:after {
    top: 12px;
    left: 12px;
}

.competitive-store__checkboxes .form-check.custom-checkbox .form-check-input:checked ~ .form-check-label:after {
    left: 28px;
}

.competitive-store__checkboxes .form-check.custom-checkbox:after {
    z-index: 1;
}

.competitive-store__radios {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.competitive-store__radios .form-check.custom-radio {
    min-width: 180px;
    position: relative;
    overflow: hidden;
}

.competitive-store__radios .form-check.custom-radio:after {
    position: absolute;
    display: block;
    content: '';
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.competitive-store__radios .form-check.custom-radio:hover:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.competitive-store__radios .form-check.custom-radio .form-check-label {
    width: 100%;
    white-space: nowrap;
    padding: 10px 10px 10px 40px;
    z-index: 0;
}

.competitive-store__radios .form-check.custom-radio .form-check-label:before {
    left: 13px;
}

.competitive-store__radios .form-check.custom-radio .form-check-label:after {
    left: 10px;
}

.competitive-store__radios .form-check.custom-radio:after {
    z-index: 1;
}

.competitive-store__row--error {
    border-radius: 4px;
    border: 1px solid #eb2013;
}

.okc-table__confirm-custom thead th,
.okc-table__confirm-custom thead td,
.okc-table__confirm-custom tbody th,
.okc-table__confirm-custom tbody td {
    width: 95px;
    min-width: 95px;
}

.okc-table__confirm-custom thead th:first-child,
.okc-table__confirm-custom thead td:first-child,
.okc-table__confirm-custom tbody th:first-child,
.okc-table__confirm-custom tbody td:first-child {
    width: 130px;
    min-width: 130px;
}

.okc-table__confirm-custom thead th:nth-child(2),
.okc-table__confirm-custom thead td:nth-child(2),
.okc-table__confirm-custom tbody th:nth-child(2),
.okc-table__confirm-custom tbody td:nth-child(2) {
    width: 160px;
    min-width: 160px;
}

.okc-table__confirm-custom thead th:nth-child(9),
.okc-table__confirm-custom thead td:nth-child(9),
.okc-table__confirm-custom tbody th:nth-child(9),
.okc-table__confirm-custom tbody td:nth-child(9) {
    width: 70px;
    min-width: 70px;
    max-width: 70px;
}

.okc-table__confirm-custom thead th:nth-child(n + 13):nth-child(-n + 14),
.okc-table__confirm-custom thead td:nth-child(n + 13):nth-child(-n + 14),
.okc-table__confirm-custom tbody th:nth-child(n + 13):nth-child(-n + 14),
.okc-table__confirm-custom tbody td:nth-child(n + 13):nth-child(-n + 14) {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
}

.okc-table__confirm-custom thead th:last-child,
.okc-table__confirm-custom thead td:last-child,
.okc-table__confirm-custom tbody th:last-child,
.okc-table__confirm-custom tbody td:last-child {
    width: 200px;
    min-width: 200px;
}

.okc-table__confirm-custom .form-textarea .form-control {
    min-height: 40px;
    height: 40px;
    padding: 10px 16px;
}

.okc-table__toggle-btn tr:first-child {
    background: transparent;
}

.okc-table__toggle-btn tr:first-child th {
    border: 2px solid transparent;
    border-bottom: 2px solid #afafaf;
}

.okc-table__toggle-btn tr:first-child th:nth-child(n + 2):nth-child(-n + 3) {
    width: 60px;
    min-width: 60px;
    padding: 5px;
    background: transparent;
}

.okc-table__toggle-btn tr:first-child th:after {
    display: none;
}

.okc-table__toggle-btn tr:first-child .btn {
    font-size: 10px;
    min-width: 51px;
    width: 100%;
    padding: 5px;
}

.okc-table__toggle-btn tr:first-child th:nth-child(3) .btn:last-child {
    border-left: 1px solid #e1e6ea;
}

.okc-table .warning {
    background: #ead1dc;
}

.okc-table__confirm tbody .active {
    background: #d16e05;
    color: #fff;
}

.okc-table__confirm tbody .active .form-control.form-control--input {
    background: #d16e05;
    color: #fff;
}

.okc-table__confirm tbody .active .form-control.form-control--input:focus {
    border-color: #e1e6ea;
}

.okc-table__confirm tbody .active:hover {
    background: #d16e05;
}

.okc-table__confirm tbody .active td:after {
    background: rgba(255, 255, 255, 0.25);
}

.confirm-registration__des {
    cursor: pointer;
}

.confirm-registration__des .form-check.custom-checkbox {
    pointer-events: none;
}

.confirm-registration__set,
.confirm-registration__remand {
    height: 1px;
    padding: 0 !important;
}

.confirm-registration__set .form-check.custom-checkbox,
.confirm-registration__set .form-check-label,
.confirm-registration__remand .form-check.custom-checkbox,
.confirm-registration__remand .form-check-label {
    height: 100% !important;
}

.confirm-registration__set .form-check-label,
.confirm-registration__remand .form-check-label {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.confirm-registration__set .form-check-label:after,
.confirm-registration__remand .form-check-label:after {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}

/* .confirm-registration--2nd .okc-table thead td:after,
.confirm-registration--2nd .okc-table tbody td:after {
    z-index: -1;
} */

/* .confirm-registration--2nd .okc-table thead th,
.confirm-registration--2nd .okc-table thead td,
.confirm-registration--2nd .okc-table tbody th,
.confirm-registration--2nd .okc-table tbody td {
    width: 100px;
    min-width: 100px;
} */

/* .confirm-registration--2nd .okc-table thead th:first-child,
.confirm-registration--2nd .okc-table thead td:first-child,
.confirm-registration--2nd .okc-table tbody th:first-child,
.confirm-registration--2nd .okc-table tbody td:first-child {
    width: 130px;
    min-width: 130px;
} */

/* .confirm-registration--2nd .okc-table thead th:nth-child(2),
.confirm-registration--2nd .okc-table thead td:nth-child(2),
.confirm-registration--2nd .okc-table tbody th:nth-child(2),
.confirm-registration--2nd .okc-table tbody td:nth-child(2) {
    width: 150px;
    min-width: 150px;
} */

/* .confirm-registration--2nd .okc-table thead th:nth-child(13),
.confirm-registration--2nd .okc-table thead td:nth-child(13),
.confirm-registration--2nd .okc-table tbody th:nth-child(13),
.confirm-registration--2nd .okc-table tbody td:nth-child(13) {
    width: 120px;
    min-width: 120px;
} */

/* .confirm-registration--2nd .okc-table thead th:nth-last-child(-n + 3),
.confirm-registration--2nd .okc-table thead td:nth-last-child(-n + 3),
.confirm-registration--2nd .okc-table tbody th:nth-last-child(-n + 3),
.confirm-registration--2nd .okc-table tbody td:nth-last-child(-n + 3) {
    width: 60px;
    min-width: 60px;
} */

/* .confirm-registration--2nd .okc-table thead .form-check.custom-checkbox,
.confirm-registration--2nd .okc-table tbody .form-check.custom-checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
} */

/* .confirm-registration--2nd .okc-table thead .form-check.custom-checkbox .form-check-label,
.confirm-registration--2nd .okc-table tbody .form-check.custom-checkbox .form-check-label {
    height: 20px;
    width: 100%;
    padding: 0;
} */

/* .confirm-registration--2nd .okc-table thead .form-check.custom-checkbox .form-check-label:before,
.confirm-registration--2nd .okc-table tbody .form-check.custom-checkbox .form-check-label:before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
} */

/* .confirm-registration--2nd .okc-table thead .btn.btn--primary:hover,
.confirm-registration--2nd .okc-table tbody .btn.btn--primary:hover {
    background: #ff890a;
} */

/* .confirm-registration--2nd .okc-table thead .btn.btn--primary:after,
.confirm-registration--2nd .okc-table tbody .btn.btn--primary:after {
    display: none;
} */

/* .confirm-registration--2nd .okc-table thead tr:first-child th {
    border: 2px solid transparent;
    border-bottom: 2px solid #afafaf;
} */

.confirm-registration--3nd .okc-table thead td:after,
.confirm-registration--3nd .okc-table tbody td:after {
    z-index: -1;
}

.confirm-registration--3nd .okc-table thead th,
.confirm-registration--3nd .okc-table thead td,
.confirm-registration--3nd .okc-table tbody th,
.confirm-registration--3nd .okc-table tbody td {
    width: 100px;
    min-width: 100px;
}

.confirm-registration--3nd .okc-table thead th:nth-child(-n + 2),
.confirm-registration--3nd .okc-table thead td:nth-child(-n + 2),
.confirm-registration--3nd .okc-table tbody th:nth-child(-n + 2),
.confirm-registration--3nd .okc-table tbody td:nth-child(-n + 2) {
    width: 100px;
    min-width: 100px;
}

.confirm-registration--3nd .okc-table thead th:nth-child(3),
.confirm-registration--3nd .okc-table thead td:nth-child(3),
.confirm-registration--3nd .okc-table tbody th:nth-child(3),
.confirm-registration--3nd .okc-table tbody td:nth-child(3) {
    width: 150px;
    min-width: 150px;
}

.confirm-registration--3nd .okc-table thead th:nth-child(4),
.confirm-registration--3nd .okc-table thead td:nth-child(4),
.confirm-registration--3nd .okc-table tbody th:nth-child(4),
.confirm-registration--3nd .okc-table tbody td:nth-child(4) {
    width: 250px;
    min-width: 250px;
}

.confirm-registration--3nd .okc-table thead th:nth-child(7),
.confirm-registration--3nd .okc-table thead td:nth-child(7),
.confirm-registration--3nd .okc-table tbody th:nth-child(7),
.confirm-registration--3nd .okc-table tbody td:nth-child(7) {
    width: 60px;
    min-width: 60px;
}

.confirm-registration--3nd .okc-table thead th:nth-child(8),
.confirm-registration--3nd .okc-table thead td:nth-child(8),
.confirm-registration--3nd .okc-table tbody th:nth-child(8),
.confirm-registration--3nd .okc-table tbody td:nth-child(8) {
    width: 150px;
    min-width: 150px;
}

.confirm-registration--3nd .okc-table thead th:last-child,
.confirm-registration--3nd .okc-table thead td:last-child,
.confirm-registration--3nd .okc-table tbody th:last-child,
.confirm-registration--3nd .okc-table tbody td:last-child {
    width: 250px;
    min-width: 250px;
}

.confirm-registration--3nd .okc-table thead .form-check.custom-checkbox,
.confirm-registration--3nd .okc-table tbody .form-check.custom-checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.confirm-registration--3nd .okc-table thead .form-check.custom-checkbox .form-check-label,
.confirm-registration--3nd .okc-table tbody .form-check.custom-checkbox .form-check-label {
    height: 20px;
    width: 100%;
    padding: 0;
}

.confirm-registration--3nd .okc-table thead .form-check.custom-checkbox .form-check-label:before,
.confirm-registration--3nd .okc-table tbody .form-check.custom-checkbox .form-check-label:before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.confirm-registration--3nd .okc-table thead tr:last-child th:first-child {
    border-left: 2px solid #afafaf;
}

.confirm-registration--3nd .okc-table tbody tr td:first-child {
    border-left: 2px solid #afafaf;
}

.registration-edit-modal .okc-result-form__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #f0f0f0;
    border-radius: 4px;
    font-weight: 700;
    padding: 5px 20px;
    font-size: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.registration-edit-modal .okc-result-form__title p {
    margin: 0;
}

@media (max-width: 991.98px) {
    .registration-edit-modal .okc-result-form__title p {
        margin-bottom: 10px;
    }
}

.registration-edit-modal .okc-result-form__blank {
    height: 40px;
}

.registration-edit-modal .okc-result-form .okc-card__row {
    padding: 24px 0;
    margin: 0 30px;
}

.registration-edit-modal .okc-result-form .okc-card__row:not(:last-child) {
    border-bottom: 1px solid #cacaca;
}

.registration-edit-modal .okc-result-form .form-check.custom-radio {
    min-width: 165px;
    border-radius: 4px;
    padding: 0;
    position: relative;
    overflow: hidden;
}

.registration-edit-modal .okc-result-form .form-check.custom-radio:after {
    position: absolute;
    display: block;
    content: '';
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.registration-edit-modal .okc-result-form .form-check.custom-radio:hover:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.registration-edit-modal .okc-result-form .form-check.custom-radio .form-check-label {
    width: 100%;
    white-space: nowrap;
    padding: 13px 10px 13px 40px;
    z-index: 2;
    line-height: 14px;
    border: 1px solid #fff;
    border-radius: 4px;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
}

.registration-edit-modal .okc-result-form .form-check.custom-radio .form-check-label:before {
    left: 15px;
    top: 50%;
}

.registration-edit-modal .okc-result-form .form-check.custom-radio .form-check-label:after {
    top: 20px;
    left: 12px;
}

.registration-edit-modal .okc-result-form .form-check.custom-radio .form-check-label:hover {
    border: 1px solid rgba(0, 0, 0, 0.01);
}

.registration-edit-modal .okc-result-form .form-check.custom-radio .form-check-input:focus ~ .form-check-label {
    border: 1px solid #d66f00;
}

.registration-edit-modal .okc-result-form .form-check.custom-radio:after {
    z-index: 1;
}

.registration-edit-modal .okc-result-form .form-check.custom-checkbox {
    min-width: 180px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

.registration-edit-modal .okc-result-form .form-check.custom-checkbox:after {
    position: absolute;
    display: block;
    content: '';
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.registration-edit-modal .okc-result-form .form-check.custom-checkbox:hover:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.registration-edit-modal .okc-result-form .form-check.custom-checkbox .form-check-label {
    width: 100%;
    white-space: normal;
    padding: 13px 10px 13px 40px;
    z-index: 2;
    line-height: 14px;
    border: 1px solid #fff;
    border-radius: 4px;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
}

.registration-edit-modal .okc-result-form .form-check.custom-checkbox .form-check-label:before {
    top: 10px;
    left: 10px;
}

.registration-edit-modal .okc-result-form .form-check.custom-checkbox .form-check-label:after {
    top: 12px;
    left: 12px;
}

.registration-edit-modal .okc-result-form .form-check.custom-checkbox .form-check-label:hover {
    border: 1px solid rgba(0, 0, 0, 0.01);
}

.registration-edit-modal .okc-result-form .form-check.custom-checkbox .form-check-input:focus ~ .form-check-label {
    border: 1px solid #d66f00;
}

.registration-edit-modal .okc-result-form .form-check.custom-checkbox .form-check-input:checked ~ .form-check-label:after {
    left: 28px;
}

.registration-edit-modal .okc-result-form .form-check.custom-checkbox:after {
    z-index: 1;
}

.registration-edit-modal .okc-result-form .form-control.form-control--input {
    text-align: left;
}

.registration-edit-modal .okc-result-form .form-control.form-control--input.js-datepicker {
    text-align: center;
}

.auth {
    height: 100vh;
}

.auth__inner {
    padding: 100px 0 50px;
}

.auth__logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 30px;
}

.auth__logo--error {
    margin-bottom: 25px;
}

.auth__logo--error .icon-image--broken-link {
    width: 150px;
    height: 150px;
    background-image: url('../src/assets/images/broken-link.svg');
}

.auth__logo img {
    width: 100px;
}

.auth__form {
    border: 1px solid #f0f0f0;
    padding: 40px 20px;
    border-radius: 4px;
    background: #fff;
}

.auth__action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 40px;
}

.auth__error h3 {
    font-size: 80px;
    margin: 50px 0 25px;
    color: #555;
}

.auth__error p {
    font-size: 20px;
    margin: 0 0 50px;
    color: #555;
}

.master-list__table thead tr th {
    border-bottom: 0 solid transparent;
}

.master-list__table thead tr:first-child th {
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #afafaf;
}

.master-list__table thead tr:last-child th {
    border-top: 2px solid #afafaf;
}

.master-list__table thead tr:last-child th:first-child {
    border-left: 2px solid #afafaf;
}

.master-list__table thead tr:last-child th:last-child {
    border-right: 2px solid #afafaf;
}

.master-list__table thead th {
    background-color: #f0f0f0;
}

.master-list__table thead th:after {
    display: none;
}

.master-list__table tbody tr td:first-child {
    border-left: 2px solid #afafaf;
}

.master-list__table tbody tr td:last-child {
    border-right: 2px solid #afafaf;
}

.master-list__table tbody tr:last-child td {
    border-bottom: 2px solid #afafaf;
}

.master-list__radios {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.master-list__radios .form-check.custom-radio {
    min-width: 180px;
    position: relative;
    overflow: hidden;
}

.master-list__radios .form-check.custom-radio:after {
    position: absolute;
    display: block;
    content: '';
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.master-list__radios .form-check.custom-radio:hover:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.master-list__radios .form-check.custom-radio .form-check-label {
    width: 100%;
    white-space: nowrap;
    padding: 10px 10px 10px 40px;
    z-index: 2;
}

.master-list__radios .form-check.custom-radio .form-check-label:before {
    left: 13px;
}

.master-list__radios .form-check.custom-radio .form-check-label:after {
    left: 10px;
}

.master-list__radios .form-check.custom-radio:after {
    z-index: 1;
}

.master-list__plus {
    width: 40px;
    height: 40px;
    min-width: 40px;
}

.user-list__table thead tr th {
    border-bottom: 0 solid transparent;
}

.user-list__table thead tr:first-child th {
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #afafaf;
}

.user-list__table thead tr:last-child th {
    border-top: 2px solid #afafaf;
}

.user-list__table thead tr:last-child th:first-child {
    border-left: 2px solid #afafaf;
}

.user-list__table thead tr:last-child th:last-child {
    border-right: 2px solid #afafaf;
}

.user-list__table thead th {
    background-color: #f0f0f0;
}

.user-list__table thead th:after {
    display: none;
}

.user-list__table tbody tr td:first-child {
    border-left: 2px solid #afafaf;
}

.user-list__table tbody tr td:last-child {
    border-right: 2px solid #afafaf;
}

.user-list__table tbody tr:last-child td {
    border-bottom: 2px solid #afafaf;
}

.price-request .okc-table {
    background: transparent;
}

.price-request .okc-table thead tr,
.price-request .okc-table tbody tr {
    background: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.price-request .okc-table thead tr:hover,
.price-request .okc-table tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.price-request .okc-table thead th,
.price-request .okc-table tbody th {
    background-color: #f0f0f0;
}

.price-request .okc-table thead th,
.price-request .okc-table thead td,
.price-request .okc-table tbody th,
.price-request .okc-table tbody td {
    width: 80px;
    min-width: 80px;
}

.price-request .okc-table thead th:first-child,
.price-request .okc-table thead td:first-child,
.price-request .okc-table tbody th:first-child,
.price-request .okc-table tbody td:first-child {
    width: 130px;
    min-width: 130px;
}

.price-request .okc-table thead th:nth-child(2),
.price-request .okc-table thead td:nth-child(2),
.price-request .okc-table tbody th:nth-child(2),
.price-request .okc-table tbody td:nth-child(2) {
    width: 200px;
    min-width: 200px;
}

.price-request .okc-table thead th:nth-child(9),
.price-request .okc-table thead td:nth-child(9),
.price-request .okc-table tbody th:nth-child(9),
.price-request .okc-table tbody td:nth-child(9) {
    width: 60px;
    min-width: 60px;
}

.price-request .okc-table thead th:nth-last-child(-n + 2),
.price-request .okc-table thead td:nth-last-child(-n + 2),
.price-request .okc-table tbody th:nth-last-child(-n + 2),
.price-request .okc-table tbody td:nth-last-child(-n + 2) {
    width: 60px;
    min-width: 60px;
}

.price-request .okc-table thead td a,
.price-request .okc-table tbody td a {
    color: #b36109;
    font-weight: 400;
}

.price-request .okc-table thead .form-check.custom-checkbox,
.price-request .okc-table tbody .form-check.custom-checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.price-request .okc-table thead .form-check.custom-checkbox .form-check-label,
.price-request .okc-table tbody .form-check.custom-checkbox .form-check-label {
    height: 20px;
    width: 100%;
    padding: 0;
}

.price-request .okc-table thead .form-check.custom-checkbox .form-check-label:after,
.price-request .okc-table tbody .form-check.custom-checkbox .form-check-label:after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.price-request .okc-table thead .form-check.custom-checkbox .form-check-label:before,
.price-request .okc-table tbody .form-check.custom-checkbox .form-check-label:before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.price-request .okc-table thead .form-check.custom-checkbox .form-check-input:checked ~ .form-check-label:after,
.price-request .okc-table tbody .form-check.custom-checkbox .form-check-input:checked ~ .form-check-label:after {
    left: calc(50% + 8px);
}

.price-request .okc-table thead .btn.btn--secondary,
.price-request .okc-table tbody .btn.btn--secondary {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.price-request .okc-table thead .btn.btn--secondary:after,
.price-request .okc-table tbody .btn.btn--secondary:after {
    display: none;
}

.price-request .okc-table thead .btn.btn--secondary:hover,
.price-request .okc-table tbody .btn.btn--secondary:hover {
    background: #d66f00;
    color: #fff;
}

.price-request .okc-table thead tr th {
    border-bottom: 0 solid transparent;
}

.price-request .okc-table thead tr:last-child th {
    border-top: 2px solid #afafaf;
}

.price-request .okc-table thead tr:last-child th:first-child {
    border-left: 2px solid #afafaf;
}

.price-request .okc-table thead tr:last-child th:last-child {
    border-right: 2px solid #afafaf;
}

.price-request .okc-table tbody tr td:first-child {
    border-left: 2px solid #afafaf;
}

.price-request .okc-table tbody tr td:last-child {
    border-right: 2px solid #afafaf;
}

.price-request .okc-table tbody tr:last-child td {
    border-bottom: 2px solid #afafaf;
}

.okc-modal .okc-modal__body {
    padding: 40px 80px 0;
}

.okc-modal .okc-modal__body .okc-card {
    margin-bottom: 0;
}

.okc-modal .okc-modal__footer {
    padding: 20px 80px 20px;
}

.deviate-warning {
    background: #e5969c !important;
}

.freeze-column {
    position: sticky !important;
    z-index: 2;
}

.border-error {
    border: 1px solid #eb2013 !important;
    border-radius: 4px;
}

#deleted-csv-table {
    margin-top: 4%;
}

.radio-disabled {
    pointer-events: none;
    opacity: 0.5;
}

#competitor-price-items-modal th,
#competitor-prohibited-items-modal th {
    background: #f0f0f0;
    border-top: 2px solid #afafaf;
    padding: 10px 3px;
}

#competitor-price-items-modal thead tr:last-child th:first-child,
#competitor-prohibited-items-modal thead tr:last-child th:first-child,
#competitor-price-items-modal tbody td:first-child,
#competitor-prohibited-items-modal tbody td:first-child {
    border-left: 2px solid #afafaf;
}

#competitor-price-items-modal tbody tr td,
#competitor-prohibited-items-modal tbody tr td {
    padding: 14px;
}

#competitor-price-items-modal thead tr:last-child th:last-child,
#competitor-prohibited-items-modal thead tr:last-child th:last-child,
#competitor-price-items-modal tbody td:last-child,
#competitor-prohibited-items-modal tbody td:last-child {
    border-right: 2px solid #afafaf;
}

#competitor-price-items-modal tbody tr:last-child td,
#competitor-prohibited-items-modal tbody tr:last-child td {
    border-bottom: 2px solid #afafaf;
    min-width: 75px;
}

.form-control--input-max-120 {
    max-width: 120px;
}

.nbpbkbn-modal .okc-modal__dialog {
    max-width: 900px;
}

.nbpbkbn-modal .okc-modal__dialog th:last-child,
.nbpbkbn-modal .okc-modal__dialog th:nth-child(3),
.nbpbkbn-modal .okc-modal__dialog td:nth-child(3),
.nbpbkbn-modal .okc-modal__dialog td:last-child {
    width: 120px;
    min-width: 120px;
}

.nbpbkbn-checkbox .form-check-label {
    height: 40px;
}

@media (max-width: 420px) {
    .select-store .competitive-store__checkboxes .form-check.custom-checkbox .form-check-label {
        padding: 10px 10px 3px 40px;
    }
}

button.btn.confirm-registration__all-set.btn--primary,
button.btn.confirm-registration__all-set.btn--secondary,
button.confirm-registration__all-remand.btn--primary,
button.confirm-registration__all-remand.btn--secondary {
    height: 22px;
    font-size: 12px;
}

button.btn.price-request__all.btn--primary,
button.btn.price-request__all.btn--secondary {
    font-size: 10px;
    width: 51px;
    padding: 5px;
}

th.invisible.column {
    height: 36px;
}

.ok_tab_table {
    position: relative;
}

.fixed_height {
    height: 40px;
}

.linked-item {
    background: #add8e6 !important;
}

.v-back-gray {
    background-color: gray;
    color: #fff;
}

.v-back-gray:hover {
    background-color: #646363 !important;
}
