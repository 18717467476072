.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition:
        border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition:
        border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.form-control--input {
    border-radius: 4px;
    border: 1px solid #e1e6ea;
    color: #000;
    font-size: 13px;
    padding: 16px;
    height: 40px;
}

.form-control--input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    border-color: #d66f00;
}

.form-control--input::-webkit-input-placeholder {
    color: #afafaf;
}

.form-control--input::placeholder {
    color: #afafaf;
}
