.btn {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    padding: 9px 17.5px;
    min-width: 88px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border: 0;
    position: relative;
    overflow: hidden;
}

.btn:after {
    position: absolute;
    display: block;
    content: '';
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.btn:hover:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.btn:after {
    z-index: 1;
}

.btn.focus,
.btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}

.btn:hover {
    color: #fff;
}

.btn--square {
    width: 57px;
    height: 57px;
    padding: 0;
    min-width: unset;
}

.btn--square:hover {
    color: #ef7a00;
}

.btn--danger {
    color: #fff;
    background: #ff430a;
}

.btn--danger:hover {
    color: #fff;
    background: #b92b00;
}

.btn--green {
    color: #fff;
    background: #188814;
}

.btn--green:hover {
    color: #fff;
    background: #047500;
}

.btn--primary {
    color: #fff;
    background: #d66f00;
}

.btn--primary:after {
    background: rgba(255, 255, 255, 0.2);
}

.btn--secondary {
    color: #555;
    background: #ddd;
}

.btn--secondary:hover {
    color: #000;
}

.btn--sm {
    font-size: 12px;
    padding: 4px;
    min-width: 60px;
}

.btn--lg {
    font-size: 16px;
    padding: 10px 40px;
}

.btn--xl {
    padding: 15px 90px;
    font-size: 18px;
}

.btn--icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
}

.btn:disabled,
.btn--disabled {
    cursor: not-allowed !important;
    opacity: 0.75;
}

.btn-lg,
.btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block + .btn-block {
    margin-top: 0.5rem;
}

.btn--primary {
    color: #fff;
    background: #d66f00;
}

.btn--primary:hover {
    color: #fff !important;
    background: rgb(214, 111, 0, 0.8) !important;
}

.btn--disabled {
    cursor: not-allowed !important;
    opacity: 0.75;
}
