.product-category:nth-child(-n + 3) {
    margin-bottom: 15px;
}

.row .no-gutters {
    overflow: hidden !important;
}

.okc-table--margin-bottom {
    background: #fff;
    margin-bottom: 50px;
}

.okc-table--margin-bottom18 {
    background: #fff;
    margin-bottom: 18px;
}

.form-control--error {
    border-color: #eb2013;
}

.okc-table--margin-bottom20 {
    margin-bottom: 20px;
}

.okc-table--margin-bottom50 {
    margin-bottom: 50px;
}

.okc-table-title {
    background-color: rgb(240, 240, 240);
    font-weight: 700;
    font-size: 20px;
    padding: 10px 3px;
}
