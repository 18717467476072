.competitor-store-list-wrapper {
    border: 1px solid #cacaca;
    border-top: none;
    margin: 0 30px;
}
.competitor-store-header {
    font-weight: 600;
}

.competitor-store-list-wrapper .row.okc-card__row {
    margin: 0;
}

.competitor-store-header > div {
    border-bottom: 1px solid #cacaca;
    background-color: #f0f0f0;
}

.okc-result-form .okc-card__row {
    padding: 24px 0;
    /* margin: 0 30px; */
}

.store-info {
    justify-content: space-evenly;
}

.competitor-store-list {
    max-height: 300px;
    overflow-y: auto;
}

.css-oq5sr-multiValue {
    display: flex;
    min-width: 0;
    background-color: #e9ecef;
    border-radius: 0.2rem;
    margin: 2px;
    box-sizing: border-box;
    color: #495057;
    border: 1px solid #d66f00;
}

.css-18ihjig {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    color: #d66f00;
    font-size: 12px;
    padding: 2px 5px;
    padding-left: 6px;
    box-sizing: border-box;
    font-weight: 600;
    background-color: white;
}

.css-1etn14k {
    /* -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center; */
    align-items: center;
    /* display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox; */
    display: flex;
    border-radius: 2px;
    background-color: #d66f00;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    color: white;
}

.css-tj5bde-Svg {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
}
