.okc-table {
    background: transparent;
    font-size: 11px;
}

.okc-table table {
    border-collapse: separate;
    border-spacing: 0;
}

.okc-table thead tr,
.okc-table tbody tr {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.okc-table table tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.okc-table thead th,
.okc-table tbody th {
    background-color: #f0f0f0;
    position: relative;
}

.okc-table thead th,
.okc-table thead td,
.okc-table tbody th,
.okc-table tbody td {
    border: none;
    border-right: 1px solid #e1e6ea;
    border-bottom: 1px solid #e1e6ea;
    padding: 10px 3px;
    text-align: center;
    /* border: 1px solid #e1e6ea; */
}

.okc-table thead th:last-child,
.okc-table thead td:last-child,
.okc-table tbody th:last-child,
.okc-table tbody td:last-child {
    position: relative;
    overflow: hidden;
}

.okc-table thead td a,
.okc-table tbody td a {
    color: #b36109;
    font-weight: 400;
}

.okc-table thead .form-check.custom-checkbox,
.okc-table tbody .form-check.custom-checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.okc-table thead .form-check.custom-checkbox .form-check-label,
.okc-table tbody .form-check.custom-checkbox .form-check-label {
    height: 20px;
    width: 100%;
    padding: 0;
}

.okc-table thead .form-check.custom-checkbox .form-check-label:after,
.okc-table tbody .form-check.custom-checkbox .form-check-label:after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.okc-table thead .form-check.custom-checkbox .form-check-label:before,
.okc-table tbody .form-check.custom-checkbox .form-check-label:before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.okc-table thead .form-check.custom-checkbox .form-check-input:checked ~ .form-check-label:after,
.okc-table tbody .form-check.custom-checkbox .form-check-input:checked ~ .form-check-label:after {
    left: calc(50% + 8px);
}

.okc-table thead .btn.btn--secondary,
.okc-table tbody .btn.btn--secondary {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.okc-table thead .btn.btn--secondary:after,
.okc-table tbody .btn.btn--secondary:after {
    display: none;
}

.okc-table thead .btn.btn--secondary:hover,
.okc-table tbody .btn.btn--secondary:hover {
    background: #d66f00;
    color: #fff;
}

.okc-table thead tr th {
    white-space: break-spaces;
    border-bottom: 0 solid transparent;
}

.okc-table thead tr:last-child th {
    border-top: 2px solid #afafaf;
}

.okc-table thead tr:last-child th:first-child {
    border-left: 2px solid #afafaf;
}

.okc-table thead tr:last-child th:last-child {
    border-right: 2px solid #afafaf;
}

.okc-table tbody tr td:first-child {
    border-left: 2px solid #afafaf;
}

.okc-table tbody tr td:last-child {
    border-right: 2px solid #afafaf;
}

.okc-table tbody tr:last-child td {
    border-bottom: 2px solid #afafaf;
}

.okc-table table thead th:hover,
.okc-table table thead td:hover,
.okc-table table tbody th:hover,
.okc-table table tbody td:hover {
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
}

.okc-table table thead tr {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.okc-table table thead tr th,
.okc-table table thead tr td {
    font-weight: 700;
    border-top-color: #f8fafc;
    border-left-color: #f8fafc;
    padding: 15px 8px;
}

.okc-table table thead tr .title {
    font-size: 20px;
}

.okc-table table thead tr .title:after {
    display: none;
}

.okc-table table thead .highlight {
    background-color: #f0f0f0;
}

.okc-table table thead .sort {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.okc-table table thead .sort:before {
    width: 10px;
    height: 10px;
    border-top: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000;
    border-left: 5px solid transparent;
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    right: 2px;
    -webkit-transform: translateY(calc(-50% - 2.5px));
    transform: translateY(calc(-50% - 2.5px));
    font-size: 10px;
    font-weight: 700;
}

.okc-table table thead .sort.active:before {
    -webkit-transform: translateY(calc(-50% + 2.5px)) rotate(-180deg);
    transform: translateY(calc(-50% + 2.5px)) rotate(-180deg);
}

.okc-table table tbody tr {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.okc-table table tbody tr td {
    padding: 8px;
}

.okc-table .cell-border {
    border-right: 2px solid #afafaf !important;
}

.okc-table .cell-border-left {
    border-left: 2px solid #afafaf !important;
}

.v-select-row {
    cursor: pointer;
}

.v-select-default {
    cursor: default;
}

.v-header-title {
    padding: 3px !important;
}

.okc-table thead .checkbox {
    width: 53px;
    max-width: 53px;
    border-bottom: 0px !important;
}

.okc-table thead th .btn {
    width: 100%;
    min-width: 53px;
    padding: 0px;
    font-size: 10px !important;
}
