.okc-table.okc-table__parent tr {
    cursor: default;
}

.okc-table--margin-bottom18 {
    background: #fff;
    margin-bottom: 18px;
}

.form-radio-width {
    width: 0%;
}

.wp-pagination > .okc-pagination > #dissociated-pagination > .okc-pagination {
    margin-bottom: 0;
}
