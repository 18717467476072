.okc-sidebar {
    background: #ef7a00;
    width: 250px;
    height: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    overflow-x: hidden;
    overflow-y: auto;
}

.okc-sidebar__inner {
    width: 250px;
}

.okc-sidebar__section {
    padding: 8px;
}

.okc-sidebar__section:not(:last-child) {
    border-bottom: 1px solid #dee2e6;
}

.okc-sidebar__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 11.5px 20px;
}

.okc-sidebar__logo {
    width: 33px;
    height: 33px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33px;
    flex: 0 0 33px;
    margin-right: 10px;
}

.okc-sidebar__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #fff;
    font-weight: 700;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}

.okc-sidebar__list {
    margin: 0;
}

.okc-sidebar__item {
    cursor: pointer;
    &:not(:last-child) {
        margin-bottom: 6px;
    }
}

.okc-sidebar__link {
    position: relative;
    overflow: hidden;
    overflow: visible;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 39px;
    padding: 6px 12px;
    color: #fff;
    border-radius: 8px;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
    width: 100%;
}

.okc-sidebar__link:after {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    width: 10%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.okc-sidebar__link:hover:after {
    width: 100%;
    opacity: 1;
}

.okc-sidebar__link:hover {
    color: #fff;
}

.okc-sidebar__link:after {
    border-radius: 8px;
}

.okc-sidebar__link.active {
    background: rgba(255, 255, 255, 0.3);
}

.okc-sidebar__icon {
    font-size: 18px;
    margin-right: 15px;
    -webkit-transition: margin 0.3s ease;
    transition: margin 0.3s ease;
}

.okc-sidebar__text-wrapper {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 100%;
    height: 39px;
    position: relative;
}

.okc-sidebar__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-weight: 700;
    font-size: 11px;
    display: inline-block;
}

.okc-sidebar__content {
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}

.okc-sidebar .okc-badge {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-left: 5px;
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.okc-sidebar .okc-badge span {
    overflow: hidden;
    display: block;
}

.okc-sidebar--compact {
    width: 74px;
}

.okc-sidebar--compact .okc-sidebar__title,
.okc-sidebar--compact .okc-sidebar__content {
    opacity: 0;
}

.okc-sidebar--compact .okc-sidebar__icon {
    margin: 0 8px;
}

.okc-sidebar--compact .okc-sidebar__link {
    width: 58px;
}

.okc-sidebar--compact .okc-badge {
    left: 8px;
}

@media (hover: hover) {
    .okc-sidebar--compact:hover {
        width: 250px;
    }

    .okc-sidebar--compact:hover .okc-sidebar__title,
    .okc-sidebar--compact:hover .okc-sidebar__content {
        opacity: 1;
    }

    .okc-sidebar--compact:hover .okc-sidebar__icon {
        margin-right: 15px;
        margin-left: 0;
    }

    .okc-sidebar--compact:hover .okc-sidebar__link {
        width: 100%;
    }

    .okc-sidebar--compact:hover .okc-badge {
        left: 0;
    }
}
