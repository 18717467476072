.input_dropdown {
    position: relative;
}

.icon_arrow {
    position: absolute;
    top: 8px;
    right: 10px;
}

.select-wrapper__control .select__control--menu-is-open {
    border: 1px solid #d66f00 !important;
}

.select-wrapper .select__option:hover {
    background-color: #ffc282 !important;
    opacity: 0.6 !important;
    color: #ef7a00;
}

.select-wrapper .select__option--is-selected {
    background-color: #ef7a00 !important;
}
