.v-width-auto {
    width: auto;
}
.v-base_width--1 {
    width: 1%;
}
.v-base_width--2 {
    width: 2%;
}
.v-base_width--3 {
    width: 3%;
}
.v-base_width--4 {
    width: 4%;
}

.v-base_width--5 {
    width: 5%;
}

.v-base_width--6 {
    width: 6%;
}
.v-base_width--7 {
    width: 7%;
}

.v-base_width--8 {
    width: 8%;
}

.v-base_width--9 {
    width: 9%;
}

.v-base_width--10 {
    width: 10%;
}

.v-base_width--12 {
    width: 12%;
}

.v-base_width--15 {
    width: 15%;
}

.v-base_minWidth--30 {
    min-width: 30px !important;
}

.v-base_minWidth--40 {
    min-width: 40px !important;
}

.v-base_minWidth--50 {
    min-width: 50px !important;
}

.v-base_minWidth--52 {
    min-width: 52px;
}

.v-base_minWidth--57 {
    min-width: 57px;
}

.v-base_minWidth--65 {
    min-width: 65px !important;
}

.v-base_minWidth--60 {
    min-width: 60px !important;
}

.v-base_minWidth--75 {
    min-width: 75px !important;
}

.v-base_minWidth--80 {
    min-width: 80px !important;
}

.v-base_minWidth--85 {
    min-width: 85px !important;
}

.v-base_minWidth--93 {
    min-width: 93px !important;
}

.v-base_minWidth--95 {
    min-width: 95px !important;
}

.v-base_minWidth--100 {
    min-width: 100px;
}

.v-base_minWidth--115 {
    min-width: 115px !important;
}

.v-base_minWidth--120 {
    min-width: 120px !important;
}

.v-base_minWidth--130 {
    min-width: 130px !important;
}

.v-base_minWidth--170 {
    min-width: 170px !important;
}

.v-base_minWidth--190 {
    min-width: 190px !important;
}

.v-base_minWidth--220 {
    min-width: 220px !important;
}

.v-base_minWidth--250 {
    min-width: 250px !important;
}

.v-base_minWidth--265 {
    min-width: 265px !important;
}

.v-base_minWidth--310 {
    min-width: 310px !important;
}

.v-base_minWidth--350 {
    min-width: 350px !important;
}

.v-minHeight-46 {
    height: 46px;
    min-height: 46px !important;
}

.freeze-right-0 {
    right: 0px;
}

.freeze-right-53 {
    right: 53px;
}

.freeze-right-60 {
    right: 60px;
}

.freeze-right-80 {
    right: 80px;
}

.freeze-right-100 {
    right: 100px;
}

.freeze-right-120 {
    right: 120px;
}

.freeze-right-133 {
    right: 133px;
}

.freeze-right-160 {
    right: 160px;
}

.text-orange {
    color: #ef7a00;
}
