.confirm-registration-competitive .form-textarea .form-control {
    min-height: 40px;
    height: 40px;
    padding: 10px 16px;
    overflow-x: hidden;
}

.modal-content .approval-source {
    display: flex;
    border-top: 1px solid #cacaca;
    padding: 30px 10% 20px;
    justify-content: center;
}

.modal-content .approval-date {
    display: flex;
    padding: 0 5% 20px;
}

.modal-content .approval-date__tilde {
    margin: 10px;
}

.modal-content .approval-name {
    display: flex;
    padding: 0 25% 20px;
}

.approval-name .form-control--input {
    width: 266px;
}
