.confirm-registration-competitive-remand .table-responsive {
    margin: 0 0 50px 0;
}

p.okc-error.mt-2.mb-0 {
    position: static !important;
}

.okc-error-2 {
    font-size: 12px;
    color: #eb2013;
    text-align: left;
}
