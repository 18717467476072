.dropzone-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #d66f00;
    padding: 20px;
    width: 100%;
    height: 200px;
    cursor: pointer;
    text-align: center;
}

.upload-button {
    background-color: #d66f00;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 16px;
    border-radius: 4px;
}

.upload-button:hover {
    background-color: #d66f00;
}

.file-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.file-preview-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

p.okc-error.mt-2.mb-0 {
    position: absolute;
    /* left: 0;
    top: 195px; */
}

.okc-upload {
    position: relative;
}
