.margin-bottom__18 {
    margin-bottom: 18px;
}

.okc-pagination--notification > #dissociated-pagination > .okc-pagination {
    margin-bottom: unset;
}

.registration-edit-modal .okc-result-form .okc-card__row:not(:last-child) {
    border-bottom: unset;
}

.registration-edit-modal .okc-result-form__title {
    font-weight: normal;
    background-color: unset;
}
