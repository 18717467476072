.Toastify .Toastify__toast-icon {
    width: 30px !important;
}

.Toastify .Toastify__toast-container {
    width: 300px !important;
}

.Toastify .Toastify__toast {
    min-height: 0px !important;
}

.Toastify .Toastify__toast-body {
    font-size: 14px !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: #13a818 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: #d11f00 !important;
}
