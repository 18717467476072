.okc-delete.d-flex.justify-content-end.mb-5 {
    margin-top: 1rem;
}

.v-base_minWidth--140 {
    min-width: 140px !important;
}

.v-base_minWidth--183 {
    min-width: 183px !important;
}

.v-base_height--40 {
    height: 40px;
}
