@media (min-width: 992px) {
    .modal-lg {
        max-width: 800px !important;
    }
}

@media (min-width: 1140px) {
    .modal-xl {
        max-width: 1140px !important;
    }
}

@media (min-width: 576px) {
    .modal-sm {
        max-width: 692px !important;
    }
}

@media (min-width: 1200px) {
    .modal-xll {
        max-width: 1200px !important;
    }
}

.modal-content {
    border: none;
}

.modal-open .modal::-webkit-scrollbar {
    display: none;
}

.modal-open .modal {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

button.btn.btn--primary {
    color: #fff;
    background: #d66f00;
}

button.btn.btn--secondary {
    color: #555;
    background: #ddd;
}
