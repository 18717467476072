.react-calendar {
    width: 300px;
    min-width: 300px;
    border-radius: 4px;
    padding: 0;
    z-index: 1100 !important;
    border: none;
    font-family: Arial, sans-serif;
    overflow: hidden;
}
.react-calendar__navigation {
    background-color: #d66f00;
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.react-calendar__navigation button {
    color: white;
    min-width: 44px;
    background: none;
}

.react-calendar__viewContainer {
    padding: 0 10px 0 !important;
    padding-bottom: 10px !important;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    font-family: 'Noto Sans JP', sans-serif;
}

.react-calendar__tile {
    height: 55px !important;
    text-align: center;
    border-radius: 8px;
}

.react-calendar__tile--active {
    background-color: #ef7a00;
    color: white;
}

.react-calendar__tile--now {
    background-color: #fff8dc;
    font-weight: bold;
    color: #ef7a00 !important;
    opacity: 1 !important;
}

.highlight-tile {
    background-color: #ef7a00;
    color: white;
}

.calendar-container {
    position: relative;
}

.react-calendar {
    position: absolute;
    z-index: 1000;
    top: 6px;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);
}

.calendar-top .react-calendar {
    top: unset !important;
    bottom: 39px !important;
}

.custom-date-picker {
    width: 100%;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none !important;
}

.react-calendar__month-view__days__day--weekend {
    color: unset;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
}

.react-calendar__tile--now {
    background-color: unset !important;
    font-weight: bold;
    color: #ef7a00;
}

.react-calendar__navigation__label {
    height: 50px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #bd6b08;
    height: 40px;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #ef7a00;
}

.react-calendar__month-view__weekdays {
    text-align: center;
}

.react-calendar__year-view__months__month abbr {
    display: none;
}

.react-calendar__year-view__months__month {
    flex: 0 0 25% !important;
    font-size: 13px;
}

.react-calendar__tile.react-calendar__decade-view__years__year {
    flex: 0 0 25% !important;
}

.react-calendar__tile--hasActive {
    background: #ef7a00 !important;
}

.react-calendar__tile--hasActive {
    background-color: #ef7a00 !important;
    font-weight: bold;
    color: #ffffff;
}

.react-calendar__tile {
    height: 42px;
    text-align: center;
    border-radius: 8px;
}

.react-calendar__navigation button:disabled {
    background-color: #d66f00 !important;
    color: white;
    opacity: 1;
}

.react-calendar__month-view__weekdays__weekday {
    max-width: 40px !important;
}

.react-calendar__navigation__label {
    height: 40px !important;
}

button.react-calendar__tile.react-calendar__century-view__decades__decade.custom-background {
    flex: 0 0 25% !important;
    font-size: 0px !important;
}

button.react-calendar__tile.react-calendar__century-view__decades__decade.custom-background > .century {
    font-size: 16px !important;
    margin-bottom: 8px;
}

button.react-calendar__tile.react-calendar__month-view__days__day.custom-background {
    font-size: 13px;
    height: 40px !important;
    max-width: 40px !important;
    font-size: 13px;
    font-weight: 700;
    font-family: 'Noto Sans JP', sans-serif;
}

button:not(:disabled) abbr {
    cursor: pointer !important;
}

.calendar-container:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #999;
    border-top: 0;
    border-bottom-color: rgb(239 122 0);
    position: absolute;
}

.calendar-top:before,
.calendar-top:after {
    bottom: 33px !important;
    border-top: 7px solid #fff !important;
    border-bottom: 0px !important;
    left: 20px;
    box-shadow: #000;
    z-index: 2000;
}

.react-calendar__navigation__label .react-calendar__navigation__label__labelText--from {
    font-size: 13px;
    font-weight: 700;
}

.custom-date-picker abbr {
    text-decoration: none !important;
    cursor: default !important;
}

.react-calendar__tile:disabled {
    background-color: unset;
    font-weight: 400 !important;
}

.calendar-container button:disabled {
    cursor: unset !important;
}

.calendar-container .react-calendar__navigation__arrow:disabled {
    cursor: not-allowed !important;
}

.calendar-container .react-calendar__navigation__arrow:disabled:hover {
    background: #bd6b08;
}

.react-calendar__navigation__arrow {
    font-size: 25px;
}

.react-calendar__tile:disabled:hover {
    background-color: #e6e6e6;
    color: #999;
}
