.okc-container {
    padding: 16.5px 25px !important;
}

.survey-history .okc-table-survey {
    font-size: 13px !important;
}

.survey-history .okc-table-survey table {
    border-collapse: collapse;
    border-spacing: 0;
}

.no-result {
    background: #fff;
    padding: 3em !important;
    text-align: center;
}

.survey-history .okc-table-survey table tbody tr .modal-link {
    cursor: pointer;
    color: #d66f00;
}

/* .okc-result-form__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #f0f0f0;
    border-radius: 4px;
    font-weight: 700;
    padding: 9px 20px;
    font-size: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-content: center;
} */

.okc-result-form__title p,
.okc-result-form__title p {
    margin: 0;
}

@media (max-width: 991.98px) {
    .okc-result-form__title p,
    .okc-result-form__title p {
        margin-bottom: 10px;
    }
}

.okc-result-form__blank,
.okc-result-form__blank {
    height: 40px;
}

.okc-result-form .okc-card__row,
.okc-result-form .okc-card__row {
    padding: 24px 0;
    margin: 0 30px;
}

.okc-result-form .okc-card__row:not(:last-child),
.okc-result-form .okc-card__row:not(:last-child) {
    border-bottom: 1px solid #cacaca;
}

.okc-result-form .form-check.custom-radio,
.okc-result-form .form-check.custom-radio {
    min-width: 160px;
    border-radius: 4px;
    padding: 0;
    position: relative;
    overflow: hidden;
}

.okc-result-form .form-check.custom-radio:after,
.okc-result-form .form-check.custom-radio:after {
    position: absolute;
    display: block;
    content: '';
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.okc-result-form .form-check.custom-radio:hover:after,
.okc-result-form .form-check.custom-radio:hover:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.okc-result-form .form-check.custom-radio .form-check-label,
.okc-result-form .form-check.custom-radio .form-check-label {
    width: 100%;
    white-space: nowrap;
    padding: 13px 10px 13px 40px;
    z-index: 2;
    line-height: 14px;
    border: 1px solid #fff;
    border-radius: 4px;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
}

.okc-result-form .form-check.custom-radio .form-check-label:before,
.okc-result-form .form-check.custom-radio .form-check-label:before {
    left: 15px;
    top: 50%;
}

.okc-result-form .form-check.custom-radio .form-check-label:after,
.okc-result-form .form-check.custom-radio .form-check-label:after {
    top: 20px;
    left: 12px;
}

.okc-result-form .form-check.custom-radio .form-check-label:hover,
.okc-result-form .form-check.custom-radio .form-check-label:hover {
    border: 1px solid rgba(0, 0, 0, 0.01);
}

.okc-result-form .form-check.custom-radio .form-check-input,
.okc-result-form .form-check.custom-radio .form-check-input {
    visibility: hidden;
}

.okc-result-form .form-check.custom-radio .form-check-input:focus ~ .form-check-label,
.okc-result-form .form-check.custom-radio .form-check-input:focus ~ .form-check-label {
    border: 1px solid #d66f00;
}

.okc-result-form .form-check.custom-radio:after,
.okc-result-form .form-check.custom-radio:after {
    z-index: 1;
}

.okc-result-form .form-check.custom-radio.form-check--disabled .form-check-label,
.okc-result-form .form-check.custom-radio.form-check--disabled .form-check-label {
    color: #555;
}

.okc-result-form .form-check.custom-radio.form-check--disabled .form-check-label:before,
.okc-result-form .form-check.custom-radio.form-check--disabled .form-check-label:before {
    background-color: #555;
}

.okc-result-form .form-check.custom-radio.form-check--disabled .form-check-label:after,
.okc-result-form .form-check.custom-radio.form-check--disabled .form-check-label:after {
    border-color: #555;
}

.survey-history .okc-result-form .form-check.custom-checkbox,
.okc-result-form .form-check.custom-checkbox {
    /* min-width: 180px; */
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

.okc-result-form .form-check.custom-checkbox:after,
.okc-result-form .form-check.custom-checkbox:after {
    position: absolute;
    display: block;
    content: '';
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
}

.okc-result-form .form-check.custom-checkbox:hover:after,
.okc-result-form .form-check.custom-checkbox:hover:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.okc-result-form .form-check.custom-checkbox .form-check-label,
.okc-result-form .form-check.custom-checkbox .form-check-label {
    width: 100%;
    padding: 13px 10px 13px 40px;
    white-space: normal;
    z-index: 2;
    line-height: 14px;
    border: 1px solid #fff;
    border-radius: 4px;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
}

.okc-result-form .form-check.custom-checkbox .form-check-label:before,
.okc-result-form .form-check.custom-checkbox .form-check-label:before {
    top: 10px;
    left: 10px;
}

.okc-result-form .form-check.custom-checkbox .form-check-label:after,
.okc-result-form .form-check.custom-checkbox .form-check-label:after {
    top: 12px;
    left: 12px;
}

.competitor-combination-master-modal,
.competitor-combination-master-modal {
    width: 38px;
    height: 38px;
    padding: 0;
}

.competitor-combination-master-modal:before,
.competitor-combination-master-modal:before {
    top: 8px !important;
    left: 8px !important;
}

.competitor-combination-master-modal:after,
.competitor-combination-master-modal:after {
    top: 10px !important;
    left: 10px !important;
}

.okc-result-form .form-check.custom-checkbox .form-check-label:hover,
.okc-result-form .form-check.custom-checkbox .form-check-label:hover {
    border: 1px solid rgba(0, 0, 0, 0.01);
}

.okc-result-form .form-check.custom-checkbox .form-check-input:focus ~ .form-check-label,
.okc-result-form .form-check.custom-checkbox .form-check-input:focus ~ .form-check-label {
    border: 1px solid #d66f00;
}

.okc-result-form .form-check.custom-checkbox .form-check-input:checked ~ .form-check-label:after,
.okc-result-form .form-check.custom-checkbox .form-check-input:checked ~ .form-check-label:after {
    left: 28px;
}

.okc-result-form .form-check.custom-checkbox:after,
.okc-result-form .form-check.custom-checkbox:after {
    z-index: 1;
}

.okc-result-form .form-control.form-control--input,
.okc-result-form .form-control.form-control--input {
    text-align: left;
}

.okc-result-form .form-control.form-control--input.js-datepicker,
.okc-result-form .form-control.form-control--input.js-datepicker {
    text-align: center;
}

.small-text,
.small-text {
    font-size: 12px;
}

.survey-history .okc-table-survey table thead tr th {
    background-color: #f0f0f0;
}

.survey-history .okc-table-survey table thead tr th:first-child {
    width: 273px;
    min-width: 273px;
}

.survey-history .okc-table-survey table tbody tr th {
    width: 273px;
    min-width: 273px;
    padding: 8px;
    background-color: white;
}

.survey-history.okc-table-survey table tbody tr .d-flex {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.survey-history .okc-table-survey table tbody tr th a {
    color: #d66f00;
    font-weight: 400;
}

.survey-history .okc-table-survey table tbody tr th a:first-child {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.survey-history .okc-table-survey table tbody tr th a:first-child:hover {
    color: #ef7a00;
}

.survey-history .okc-table-survey table tbody tr td {
    min-width: 86px;
    padding: 8px;
}

.survey-history .okc-table-survey table tbody tr td i {
    color: #ccc;
}

.survey-history .okc-table-survey table tbody tr .link {
    height: 24px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 24px;
    flex: 0 0 24px;
    padding: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 4px;
    background: #d66f00;
    color: #fff;
    font-size: 14px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-right: 10px;
}

.survey-history .okc-table-survey table tbody tr .link:hover {
    background: #ef7a00;
}

.survey-history .okc-table-survey table tbody tr .active i {
    color: #ef7a00;
}

.survey-history .okc-table-survey table tbody tr .highlight {
    background: #ead1dc;
}

/* .survey-history .okc-table-survey table tbody:hover {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: rgba(0, 0, 0, 0.05);
} */

.survey-history .okc-table-survey table tbody:hover .empty {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: #ccc;
}

.survey-history .okc-table-survey tbody tr:last-child td {
    border-bottom: 0px solid #afafaf !important;
}

.survey-history .okc-table-survey tbody tr td:first-child {
    border-left: 0px solid #afafaf;
}

.survey-history .okc-table-survey tbody tr td:last-child {
    border-right: 0px solid #afafaf;
}

.survey-history .okc-table-survey thead th,
.survey-history .okc-table-survey thead td,
.survey-history .okc-table-survey tbody th,
.survey-history .okc-table-survey tbody td {
    padding: 10px 3px;
    width: 95px;
    min-width: 85px;
    text-align: center;
    border: 1px solid #e1e6ea;
}

.survey-history .okc-table-survey thead tr:last-child th:first-child {
    border-left: 0px solid #afafaf;
}

.survey-history .okc-table-survey thead tr:last-child th {
    border-top: 0px solid #afafaf;
}

.survey-history .okc-table-survey thead tr:last-child th:last-child {
    border-right: 0px solid #afafaf;
}

.survey-history .okc-table-survey table thead tr th,
.survey-history .okc-table-survey table thead tr td {
    font-weight: 700;
    border-top-color: #f0f0f0;
    padding: 15px 8px !important;
}

.survey-history .okc-table-survey {
    background: #fff;
    margin-bottom: 50px;
    font-size: 13px;
}

.okc-result-form__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #f0f0f0;
    border-radius: 4px;
    font-weight: 700;
    padding: 9px 20px !important;
    font-size: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: baseline !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.okc-result-form__title p {
    margin: 0;
}

.okc-result-form .form-check.custom-radio {
    min-width: 160px;
    border-radius: 4px;
    padding: 0;
    position: relative;
    overflow: hidden;
}

.okc-table-survey table tbody td:hover {
    background: rgba(0, 0, 0, 0.1);
}

.okc-card__row [class*='col'] .okc-result-form__title {
    padding: 0;
    align-self: stretch;
    display: flex;
    align-items: normal;
}

td,
th {
    display: table-cell;
}

@media only screen and (max-width: 768px) {
    .okc-sidebar--compact {
        width: 0;
    }

    .okc-right {
        width: 100% !important;
    }

    .okc-left {
        top: 57px !important;
        position: absolute !important;
        z-index: 10;
    }

    td[data-column='none'],
    th[data-column='none'] {
        display: none;
    }
}
